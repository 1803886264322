import AppConstants from "../Constants";
import { AxiosInstance } from "./http.handler";


export const GetQuestionIds = async (accessToken, questionnairesId) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    const data = JSON.stringify({
        "request": [
            {
                "key": "objectid",
                "_id": questionnairesId
            },
            {
                "include": [
                    "questionsId"
                ]
            }
        ]
    })
    return await AxiosInstance.post(
        `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.GET_QUESTIONID}?perpage=210&next=1&source=videoInterview`,
        data, { headers: headers }
    )
        .then((response) => response
        )
        .catch((error) => error);
}

const JobInterviewID = '62a2e18859fedc706c27fe4a';

//export const GetQuestionnairesId = async (accessToken, JobInterviewID ) => {
export const GetQuestionnairesId = async (accessToken) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    const data = JSON.stringify(
        {
            "exact":
            {
                "id": JobInterviewID
            },
            "include":
                ["questionnairesId",
                    "jobName",
                    "createdDate",
                    "candidateName",
                    "candidateEmail",
                    "stage",
                    "createdBy",
                    "candidateNumber",
                    "questionnairesId",
                    "jobCode",
                    "lastModified",
                    "lastModifiedBy",
                    "scheduleInterviewId",
                    "feedback",
                    "rating"
                ],
        });
    return await AxiosInstance.post(
        `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.GET_QUESTIONNAIRESID}?source=VideoInterviewAPI`,
        data, { headers: headers }
    )
        .then((response) => response
        )
        .catch((error) => error);
}

export const GetQuestion = async (accessToken, quesId) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    const data = JSON.stringify({})
    return await AxiosInstance.post(
        `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.GET_QUESTION}/${quesId}?source=videoInterview`,
        data, { headers: headers }
    )
        .then((response) => response
        )
}

//This api is used to post resourceId , participantId , callId.



// var currentdate = new Date();
// const lastModifieddate = currentdate.toISOString();

// export const UpdateInfo = async (accessToken, candidateInfo, textValue = '') => {
//     const headers = {
//         'Content-type': 'application/json',
//         'Authorization': `Bearer ${accessToken}`
//     };
//     const data = ({
//         "jobName": candidateInfo.jobName,
//         "createdDate": candidateInfo.createdDate,
//         "candidateName": candidateInfo.candidateName,
//         "candidateEmail": candidateInfo.candidateEmail,
//         "stage": "Completed",
//         "createdBy": candidateInfo.createdBy,
//         "candidateNumber": candidateInfo.candidateNumber,
//         "questionnairesId": candidateInfo.questionnairesId,
//         "jobCode": candidateInfo.jobCode,
//         "lastModified": lastModifieddate,
//         "lastModifiedBy": candidateInfo.lastModifiedBy,
//         "scheduleInterviewId": candidateInfo.scheduleInterviewId,
//         "feedback": textValue,
//         "rating": 5,
//         "resourceId": candidateInfo.acsResourceId,
//         "callId": candidateInfo.callId,
//         "participants": [
//             {
//                 "participantId": candidateInfo.participantId
//             }
//         ]
//     })


//     return await AxiosInstance.put(
//         `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.UPDATE_INFO}/62a2e18859fedc706c27fe4a?source=videoInterview
//         `,
//         data, { headers: headers }
//     )
//         .then((response) => response
//         )
// }

export const postQuestionSetName = async (accessToken, questionSet) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    const data = JSON.stringify([questionSet])
    return await AxiosInstance.post(
        `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.POST_QUESTIONNAIRE}?source=videoInterview`,
        data, { headers: headers }
    )
        .then((response) => response
        )
}

export const postQuestions = async (accessToken, questions) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    const data = JSON.stringify(questions)
    return await AxiosInstance.post(
        `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.POST_QUESTION}?source=videoInterview`,
        data, { headers: headers }
    )
        .then((response) => response
        )
};


export const postQuestionsData = async (accessToken, questionnaireId, queData) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    const data = JSON.stringify(queData);
    console.log(queData, 'queData');
    return await AxiosInstance.put(
        `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.POST_QUESTIONNAIRE}/${questionnaireId}?source=videoInterview`,
        data, { headers: headers }
    )
        .then((response) => response
        )
};

export const updateQuestionsOrder = async (accessToken, questionnaireId, queData) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    const data = JSON.stringify(queData);
    console.log(queData, 'queDataUpdate');
    return await AxiosInstance.put(
        `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.UPDATE_QUESTION_ORDER}/${questionnaireId}?source=videoInterview`,
        data, { headers: headers }
    )
        .then((response) => response
        )
};



export const getQuestionnairesIds = async (accessToken, jobName) => {
    //console.log(accessToken, jobName);
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    let url = `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.POST_QUESTIONNAIRE}?jobName=${jobName}&source=videoInterview&fields="_id"`;
    // console.log(url.trim());
    return await AxiosInstance.get(
        url.trim(), { headers: headers }
    )
        .then((response) => response
        )
}


export const getQuestionnairesOnJobName = async (accessToken, jobname, userInfo, isAdmin) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    let userDetails = [];

    if (isAdmin === "Global Administrator" || isAdmin === "VideoInterview Administrator") {
        userDetails = [
            {
                "key": "exact",
                "deleted": false
            },
            {
                "include": [
                    "name"
                ]
            }
        ];
    }


    else {
        userDetails = [
            // {
            //     key: "contains",
            //     recruiterEmailId: userInfo.email,
            // },
            {
                "key": "exact",
                "deleted": false
            },
            {
                "include": [
                    "name",
                    "createdBy"
                ]
            }
        ];
    }


    const data = JSON.stringify({
        "request": [...userDetails]
    })

    return await AxiosInstance.post(`${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.GET_QUESTIONID}?perpage=210&next=1&source=videoInterview`, data, { headers: headers })
        .then((response) => response)
        .catch((err) => err)
}


export const getQuestionsOnQuestionnaireId = async (accessToken, questionnaireId) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    const data = JSON.stringify({
        "request": [
            {
                "key": "objectid",
                "_id": questionnaireId
            },
            {
                "include": [
                    "questionsId"
                ]
            }
        ]
    })
    return await AxiosInstance.post(`${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.GET_QUESTIONID}?perpage=210&next=1&source=videoInterview`, data, { headers: headers })
        .then((response) => response)
        .catch((err) => err)
}


export const getQuestionsOnQuestionIds = async (accessToken, questionId) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    const data = JSON.stringify({})
    return await AxiosInstance.post(`${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.GET_QUESTION}/${questionId}?source=videoInterview`, data, { headers: headers })
        .then((response) => response)
        .catch((err) => err)
};


export const deleteQuestion = async (accessToken, questionId, questionnaireId, userName) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    const data = JSON.stringify({
        "questionReferenceId": [
            questionId
        ],
        "updatedBy": userName,
        "updatedAt": new Date().toISOString(),
    })
    return await AxiosInstance.put(`${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.DELETE_QUESTION}/${questionnaireId}?source=VideoInterview`, data, { headers: headers })
        .then((response) => response)
        .catch((err) => err)
};


export const updateTime = async (accessToken, UpdatedData, questionnaireId, userName) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    const data = JSON.stringify(
        {
            "updatedAt": new Date().toISOString(),
            "updatedBy": userName,
            "questionsId": [
                {
                    "questionReferenceId": UpdatedData.id,
                    "thinkTime": UpdatedData.thinkTime,
                    "timeLimit": UpdatedData.timeLimit
                }
            ]
        })
    return await AxiosInstance.put(`${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.POST_QUESTIONNAIRE}/${questionnaireId}?source=VideoInterview`, data, { headers: headers })
        .then((response) => response)
        .catch((err) => err)
};

export const deleteQuestionnaire = async (accessToken, questionnaireId, userName) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    const data = JSON.stringify({
        "updatedBy": userName,
        "updatedAt": new Date().toISOString(),
        "deleted": true
    })
    return await AxiosInstance.put(`${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.POST_QUESTIONNAIRE}/${questionnaireId}?source=VideoInterview`, data, { headers: headers })
        .then((response) => response)
        .catch((err) => err)
};

export const editQuestion = async (accessToken, questionId, userName, question) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    const data = JSON.stringify({
        "updatedBy": userName,
        "updatedAt": new Date().toISOString(),
        "question": question,
    })
    return await AxiosInstance.put(`${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.GET_QUESTION}/${questionId}?source=VideoInterview`, data, { headers: headers })
        .then((response) => response)
        .catch((err) => err)
};