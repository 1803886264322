import AppConstants from "../Constants";
import { AxiosInstanceMail, AxiosInstance } from "./http.handler";


export const ScheduleInterviewInfotoRecruiter = async (accessToken, email, template, startDateInfo, endDateInfo, interviewType, timeZone) => {
    console.log('final template',template);
    //   return;
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    const data = JSON.stringify({
        "subject": interviewType,
        "isOnlineMeeting": false,
        "onlineMeetingProvider": "teamsForBusiness",
        "body": {
            "contentType": "HTML",
            "content": template
        },
        "start": {
            "dateTime": new Date(startDateInfo).toISOString(),
            "timeZone": timeZone.value
        },
        "end": {
            "dateTime": new Date(endDateInfo).toISOString(),
            "timeZone": timeZone.value
        },
        // "location": {
        //     "displayName": "Video Interview"
        // },
        "attendees": [
            {
                "emailAddress": {
                    "address": email,
                    "name": ""
                },
                "type": "required"
            }
        ]
    }

    )

    return await AxiosInstanceMail.post(
        `${AppConstants.serverConstants.MAIL_PATH}/event/`,
        data, { headers: headers }
    )
        .then((response) => response
        )
        .catch((error) => error);
}



export const getEmailTemplate = async (accessToken) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
    };

    return await AxiosInstance.get(
        `${AppConstants.serverConstants.API_ENDPOINTS.EMAIL_TEMPLATE}/VideoInterviewTemplate.html?source=videoInterview`, { headers: headers }
    )
        .then((response) => response
        )
        .catch((error) => error);
};

export const isMailSend = async (accessToken, userInfo, jobInterviewId, candidateId) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    const data = JSON.stringify({
        "candidateId": candidateId,
        "updatedAt": new Date().toISOString(),
        "updatedBy": userInfo,
        "mailSend": true
    }
    );
    return await AxiosInstance.put(
        `${AppConstants.serverConstants.API_ENDPOINTS.IS_MAIL_SEND}/${jobInterviewId}?source=VideoInterviewAPI`,
        data, { headers: headers }
    )
        .then((response) => response
        )
        .catch((error) => error);
};

