import * as React from 'react';
import Box from '@mui/material/Box';
import './Questionnaires.css';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import plus from '../../../../Assets/icons/plus.png';
import AddQuestionnaires from '../Popup/AddQuestionnaires';
import AddQuestion from '../Popup/AddQuestion';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { useUserAuthContext } from '../../../../utils/Contexts/Auth/UserAuthProvider';
import { getQuestionnairesOnJobName, getQuestionsOnQuestionnaireId, GetQuestionIds, getQuestionsOnQuestionIds, deleteQuestion, deleteQuestionnaire, editQuestion, updateTime, updateQuestionsOrder } from '../../../../handlers/question_handler';
import { v4 as uuidv4 } from 'uuid';
import Loader from '../../../Preloaders/Loader';
import DirectionSnackbar from '../../../Alerts/Snackbar';
import { filterDataList } from '../../../../utils/Helper/filterDataList';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { SuccessAlert } from '../../../Alerts/SuccessAlert';
import Tooltip from '@mui/material/Tooltip';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
/*----redux---*/
import { useSelector, useDispatch } from 'react-redux';
import { CI_InitialStateAction } from '../../../../utils/Redux/Actions';
import { useEffect, useState, useRef } from 'react';
import { ErrorAlert } from '../../../Alerts/ErrorAlert';
/*----redux---*/
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function Questionnaires() {
    const { accessToken, userInfo, userRole } = useUserAuthContext()
    const { questionSet, selectedJob, selectedQuestionSetId, selectedQuestions } = useSelector(state => {
        //  console.log(state.handleCreateInterviewInitialState);
        return state.handleCreateInterviewInitialState;
    });
    const dispatch = useDispatch();
    const [questionSetSelection, setQuestionSetSelection] = useState({ id: '', index: '' });
    const [questionUpdatedWithData, setQuestionUpdatedWithData] = useState(false);
    const [timeShowSelection, setTimeShowSelection] = useState([]);
    const [questionnaire_selected_questionIds, setQuestionnaire_selected_questionIds] = useState([]);
    const [deleteLoader, setDeleteLoader] = useState({ queSet: [], que: [] });
    const [updatedTimeData, setUpdatedTimeData] = useState({ timeLimit: 0, thinkTime: 0, id: '' });
    const [editQuestions, setEditQuestions] = useState('');
    const [editTime, setEditTime] = useState(false);
    const deobunceIntervalId = useRef('');
    const loaderIntervalId = useRef('');
    const questionsetLoaderIntervalId = useRef('');
    const filterListTypeRef = useRef('');
    const questionsFetchIntervalId = useRef('');
    const questionsCurrentViewRef = useRef([]);
    const filterArrayTempRef = useRef([]);
    const getQuestionStatus = useRef(false);
    const oldQuestionSetId = useRef('');
    const leftboxEl = React.useRef();
    const rightboxEl = React.useRef();
    const [tableLoaderErrInfo, setTableLoaderErrInfo] = useState({ questionset: '', questions: '' });
    const [questionLoadingErrInfo, setQuestionLoadingErrInfo] = useState(true);
    const [searchFilterText, setSearchFilterText] = React.useState({ queSet: '', ques: '' });
    const [FilteredDataArr, setFilteredDataArr] = useState([]);
    const [FilteredQuestionDataArr, setFilteredQuestionDataArr] = useState([]);
    const [questionUpdated, setQuestionUpdated] = useState({ textStatus: '', timeStatus: '', textUpdate: '', timeUpdate: '' });
    let sortedQuestionSets = sortElements([...questionSet], 'questionsetId');
    const questionsList = showCurrentSelectedQuestionSet_Questions();
    const [questionTimes, setQuestionTimes] = useState([]);
    const [isEdited, setIsEdited] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [isFieldEmpty, setIsFieldEmpty] = useState(false);
    const [orderQuestionsAfterDelete, setOrderQuestionsAfterDelete] = React.useState({ callQuestionOrderUpdateAPI: false });
    const scrolltriggerTimerRef = useRef(null);
    const inputSearchQuestion = useRef('');
    // const [deleteCheck, setDeleteCheck] = useState('false');
    const [alertMessage, setAlertMessage] = useState({ display: false, text: '' });
    const [showError, setShowError] = useState({ display: false, text: '' });
    const deleteId = useRef({ id: '', type: '' });
    // drag start 
    const dragItem = useRef();
    const dragOverItem = useRef();
    const handleDragStart = (e, position) => {
       // console.log(e, position, 'sadhkdkdgk');
        dragItem.current = position;
        e.currentTarget.style.backgroundColor = "#4fa2c9";
        e.currentTarget.style.opacity = '0.5';
        e.dataTransfer.effectAllowed = 'move';
        // e.dataTransfer.setDragImage(e.target.innerHTML);
    }
    const handleDragEnter = (e, position) => {
        dragOverItem.current = position;
        e.currentTarget.style.opacity = '0.5';
        e.currentTarget.style.backgroundColor = "#4fa2c9";
        // e.target.style.transform = 'translate(0)'
        // scroll behaviour start
        const rect = e.target.getBoundingClientRect();
        const questionScrollViewer = document.getElementById('question-list-scroll-view');
        questionScrollViewer.scroll({
            top: rect.top,
            behavior: 'smooth'
        });
        //  scroll behaviour end
    }
    const handleDragLeave = (e, position) => {
        if (dragItem.current !== position) {
            e.currentTarget.style.opacity = '1';
            e.currentTarget.style.backgroundColor = "white";
        }
        // scroll behaviour start
        const rect = e.target.getBoundingClientRect();
        const questionScrollViewer = document.getElementById('question-list-scroll-view');
        // clearTimeout(scrolltriggerTimerRef.current);
        // scrolltriggerTimerRef.current = setTimeout(() => {
        // questionScrollViewer.scroll({
        //     top: rect.top,
        //     behavior: 'smooth'
        // });
        // }, 1000);
        // scroll behaviour end
    }
    const handleDragOver = (e, index) => {
        e.preventDefault();
    }
    const handleDrop = (e) => {
        // e.stopPropagation();
        //  console.log('handleDrop', e);
        // If search filter is 
        if (inputSearchQuestion.current != '') {
            return;
        }
        dragItem.current = e.source.index;
        dragOverItem.current = e.destination.index;
        let copyFilteredQuestionDataArr = [...FilteredQuestionDataArr];
        const copyFilteredQuestionDataArrItem = copyFilteredQuestionDataArr[dragItem.current];
        copyFilteredQuestionDataArr.splice(dragItem.current, 1);
        copyFilteredQuestionDataArr.splice(dragOverItem.current, 0, copyFilteredQuestionDataArrItem);
        //console.log(copyFilteredQuestionDataArrItem, 'copyFilteredQuestionDataArrItem', dragItem.current, dragOverItem.current, copyFilteredQuestionDataArr);
        setFilteredQuestionDataArr(copyFilteredQuestionDataArr);
        // e.dataTransfer.getData('text/html');
        // e.currentTarget.style.opacity = '1';
        // e.currentTarget.style.backgroundColor = "white";
        copyFilteredQuestionDataArr = [...copyFilteredQuestionDataArr].map((element, index) => {
            element.order = index + 1;
            return element;
        })
        const _questionSet = questionSet.slice();
        const arr = _questionSet.map((question, index) => {
            if (selectedQuestionSetId === question.questionsetId) {
                // console.log(question, 'cheickquestionSet');
                question.questions = copyFilteredQuestionDataArr;
            }
            return question;
        });
        const copyFilteredQuestionDataArrPayload = [...copyFilteredQuestionDataArr].map((item, index) => {
            return {
                order: item.order,
                timeLimit: item.timeLimit,
                thinkTime: item.thinkTime,
                questionReferenceId: item.id
            }
        });
        const updateOrderPayload = {
            updatedAt: new Date().toISOString(),
            updatedBy: userInfo.userName,
            questionsId: copyFilteredQuestionDataArrPayload
        }
        updateQuestionsOrder(accessToken, selectedQuestionSetId, updateOrderPayload)
            .then(res => {
                if (res.status) {
                    if (res.data.status === 'succeed') {
                        if (res.data.data.message === "Questions order successfully updated!!") {
                        }
                    }
                }
            })
            .catch(err => {
                console.log(err);
            })
        dispatch(CI_InitialStateAction('store_ordered_questions_to_question_set', arr));
        //updateQuestionsOrder()
    }
    // drag end
    const handleClickOpen = (ids, type) => {
        setOpen(true);

        deleteId.current = { id: ids, type: type }
    };
    const handleClose = () => {
        setOpen(false);
    };
    let isAdmin = '';
    if (userRole == "Global Administrator") {
        isAdmin = "Global Administrator"
    }
    else if (userRole == "VideoInterview Administrator") {
        isAdmin = "VideoInterview Administrator"
    };
    // console.log(userRole, 'userRole');
    useEffect(() => {
        if (questionSet.length) return;
        getQuestionnairesOnJobName(accessToken, selectedJob.jobName, userInfo, userRole)
            .then(res => {
                let tempArrResponse = [...res.data.data.response].map((item, index) => {
                    return { questionsetId: item.id, questionsetName: item.name, createdBy: item.createdBy, questions: [] }
                })
                dispatch(CI_InitialStateAction('retrieve_existing_question_sets', tempArrResponse));
                if (!res.data.data.response.length) setTableLoaderErrInfo(state => ({ ...state, questionset: 'No record found' }));
                else setTableLoaderErrInfo(state => ({ ...state, questionset: '' }));
                //  console.log(tempArrResponse);
            })
            .catch(err => {
                console.log(err);
                setTableLoaderErrInfo(state => ({ ...state, questionset: 'Contact to Administrator' }));
            })
    }, []);
    useEffect(() => {
        if (selectedQuestionSetId) {
            const filteredArr = questionSet.filter(item => selectedQuestionSetId === item.questionsetId)
            if (filteredArr[0].questions.length) {
                return;
            }
            GetQuestionIds(accessToken, selectedQuestionSetId)
                .then(res => {
                    if (res.status) {
                        if (res.data.status === 'succeed') {
                            if (res.data.data.response[0].questionsId) setQuestionnaire_selected_questionIds(res.data.data.response[0].questionsId);
                            dispatch(CI_InitialStateAction('assign_que_IDs_to_retrieved_question_set', { questionsetId: res.data.data.response[0].id, array: res.data.data.response[0].questionsId }));
                        }
                    }
                    // if (!res.data.data.response[0].questionsId) setTableLoaderErrInfo(state => ({ questionset: '', questions: 'No questions found' }));
                    // else setTableLoaderErrInfo(state => ({ ...state, questions: 'loading' }));
                })
                .catch(err => {
                    console.log(err)
                    setTableLoaderErrInfo(state => ({ questionset: '', questions: 'Contact to Administrator' }));
                })
            return;
        }
        setFilteredQuestionDataArr([])
    }, [selectedQuestionSetId])
    useEffect(() => {
        /*
        * This section of useEffect is used to get the questions names on behalf of the question ids (array of question ids) stored inside the state (redux)
        */
        clearInterval(questionsFetchIntervalId.current);
        let searchQueNameStatus = true;
        setFilteredDataArr(filterDataList('questionsetName', [...sortedQuestionSets], searchFilterText.queSet));
        setFilteredQuestionDataArr(filterDataList('queName', [...questionsList], searchFilterText.ques))
        if (!questionSet.length || !getQuestionStatus.current) return;
        let questinsetTempArr = []; // store the question ids here whose question name needs to be fetched.
        [...questionSet].forEach(questionset => {
            if (questionSetSelection.id === questionset.questionsetId) {
                questinsetTempArr = questionset.questions
            }
        });
        for (let i = 0; i < questinsetTempArr.length; i++) {
            if (questinsetTempArr[i].queName) {
                searchQueNameStatus = false;
                break;
            }
        }
        if (!searchQueNameStatus) {
            return;
        }
        questionsFetchIntervalId.current = setTimeout(() => {
            // console.log('questionId',questinsetTempArr);
            [...questinsetTempArr].forEach(item => {
                // console.log('questionId',item);
                getQuestionsOnQuestionIds(accessToken, item.id)
                    .then(res => {
                        if (res.status) {
                            if (res.data.status === 'succeed') {
                                questinsetTempArr.forEach(queItem => {
                                    // console.log('filterArrayTempRef.current', queItem);
                                    if (queItem.id === item.id) {
                                        if (!filterArrayTempRef.current.includes(item.id)) {
                                            questionsCurrentViewRef.current.push({ ...queItem, queName: res.data.data.question });
                                            filterArrayTempRef.current.push(item.id)
                                        }
                                    }
                                })
                                if (questionsCurrentViewRef.current.length === questinsetTempArr.length) {
                                    if (!questionUpdatedWithData) {
                                        return;
                                    }
                                    questionsCurrentViewRef.current.sort((a, b) => {
                                        if (a.id < b.id) return -1;
                                        else if (a.id > b.id) return 1;
                                        return 0;
                                    })
                                    dispatch(CI_InitialStateAction('assign_questionNames_to_retrieved_question_set', { questionsetId: questionSetSelection.id, array: questionsCurrentViewRef.current }))
                                    getQuestionStatus.current = false;
                                    oldQuestionSetId.current = questionSetSelection.id;
                                    setQuestionUpdatedWithData(false)
                                }
                            }
                        }
                    })
                    .catch(err => console.log(err))
            })
        }, [1000]);
    }, [questionSet, questionSetSelection.id]);
    useEffect(() => {
        questionSet.forEach(set => {
            if (set.questionsetId === questionSetSelection.id) {
                if (set.questions.length) {
                    const sortedQuestionsOnOrder = [...questionsList].sort((a, b) => {
                        if (a.order < b.order) return -1;
                        else if (a.order > b.order) return 1;
                        return 0;
                    });
                    // console.log('check here questions', [...questionsList], sortedQuestionsOnOrder);
                    setFilteredQuestionDataArr(filterDataList('queName', sortedQuestionsOnOrder, searchFilterText.ques))
                }
            }
        })
    }, [questionSet, questionSetSelection.id]);
    useEffect(() => {
        if (orderQuestionsAfterDelete.callQuestionOrderUpdateAPI) {
            let copyFilteredQuestionDataArr = [...FilteredQuestionDataArr];
            copyFilteredQuestionDataArr = [...copyFilteredQuestionDataArr].map((element, index) => {
                element.order = index + 1;
                return element;
            })
            const _questionSet = questionSet.slice();
            const arr = _questionSet.map((question, index) => {
                if (selectedQuestionSetId === question.questionsetId) {
                    // console.log(question, 'cheickquestionSet');
                    question.questions = copyFilteredQuestionDataArr;
                }
                return question;
            });
            const copyFilteredQuestionDataArrPayload = [...copyFilteredQuestionDataArr].map((item, index) => {
                return {
                    order: item.order,
                    timeLimit: item.timeLimit,
                    thinkTime: item.thinkTime,
                    questionReferenceId: item.id
                }
            });
            const updateOrderPayload = {
                updatedAt: new Date().toISOString(),
                updatedBy: userInfo.userName,
                questionsId: copyFilteredQuestionDataArrPayload
            }
            updateQuestionsOrder(accessToken, selectedQuestionSetId, updateOrderPayload)
                .then(result => {
                    // console.log(result, 'updateQuestionsOrder')
                    if (res.status) {
                        if (res.data.status === 'succeed') {
                            if (res.data.datamessage === "Questions order successfully updated!!") {
                                setOrderQuestionsAfterDelete({ callQuestionOrderUpdateAPI: false });
                            }
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            dispatch(CI_InitialStateAction('store_ordered_questions_to_question_set', arr));
        }
    }, [orderQuestionsAfterDelete.callQuestionOrderUpdateAPI]);
    useEffect(() => {
        if (FilteredQuestionDataArr.length === 0) {
            dispatch(CI_InitialStateAction('remove_selected_questions', []));
        }
    }, [FilteredQuestionDataArr.length]);
    useEffect(() => {
        if (!selectedQuestionSetId) { // If questionnaire id is not selected then return from here.
            return;
        }
        clearInterval(loaderIntervalId.current);  // clear setTimeout intervalId to avoid unnecessary "no record found" message.
        let questionNameNotLoadedStatus = false;
        for (let i = 0; i < FilteredQuestionDataArr.length; i++) {
            if (!FilteredQuestionDataArr[i].queName) {
                questionNameNotLoadedStatus = true;
                break;
            }
        }
        if (FilteredQuestionDataArr.length) {
            if (questionNameNotLoadedStatus) {
                setTableLoaderErrInfo(state => ({ ...state, questions: 'loading' }));
            }
            else {
                setTableLoaderErrInfo(state => ({ ...state, questions: '' }));
                const generated_questions = [...FilteredQuestionDataArr].map(item => {
                    // console.log('questionsAttachedTempArray', item);
                    return { question: item.queName, questionId: item.id, thinkTime: item.thinkTime, timeLimit: item.timeLimit, order: item.order }
                });
                dispatch(CI_InitialStateAction('store_selected_questions', generated_questions));
            }
            // Maintaining a local state to store the timeLimit & thinkTime when time edit functionlity is enabled. (start)
            const array = [...FilteredQuestionDataArr].map(item => {
                return { id: item.id, thinkTime: item.thinkTime, timeLimit: item.timeLimit }
            });
            setQuestionTimes(array);
            // ------------------------------------------------------------------------------------------------------ (end)
        }
        else {
            setTableLoaderErrInfo(state => ({ ...state, questions: 'loading' }));
            loaderIntervalId.current = setTimeout(() => {
                setTableLoaderErrInfo(state => ({ questionset: '', questions: 'No record found' }));
            }, [1000])
        }
    }, [FilteredQuestionDataArr]);
    useEffect(() => {
        clearInterval(questionsetLoaderIntervalId.current); // Multiple render causes setTimeout to generate "no record found error" with multiple intervalID. Here clearing the setTimeout intervalId to avoid unnecessary "no record found message".
        if (!FilteredDataArr.length) {
            questionsetLoaderIntervalId.current = setTimeout(() => {
                setTableLoaderErrInfo(state => ({ ...state, questionset: 'No record found' }));
            }, [1000])
        }
        else {
            clearInterval(questionsetLoaderIntervalId.current)
            setTableLoaderErrInfo(state => ({ ...state, questionset: '' }));
        }
    }, [FilteredDataArr]);
    /*
    * Select a question set when user click on the question set list item
    */
    const handleQuestionSetSelection = (id, index) => {
        dispatch(CI_InitialStateAction('modify_selected_questions', { setId: id }));
        if ((questionSetSelection.id || selectedQuestionSetId) === id) {
            setQuestionSetSelection({ id: '', index: '' })
            // dispatch(CI_InitialStateAction('modify_selected_questions', {setId: questionSetSelection.id}));
            return
        }
        setQuestionSetSelection({ id, index })
        getQuestionStatus.current = true;
        questionsCurrentViewRef.current = [];
        filterArrayTempRef.current = [];
        setQuestionUpdatedWithData(true);
        setTableLoaderErrInfo(state => ({ ...state, questions: '' }));
    }
    /*
    * To show only the question(s) of the corresponding question set 
    */
    function showCurrentSelectedQuestionSet_Questions() {
        let arr = questionSet.filter(item => item.questionsetId === (selectedQuestionSetId || questionSetSelection.id))
        if (arr.length) {
            return arr[0].questions
        }
        return sortElements(arr, 'id');
    }
    /*
    * Toggle time limit and think time
    */
    const handleTimerPanel = (id) => {
        const temp = [...questionsList];
        const showTime_updatedQuestionList = temp.map((item) => {
            if (item.id === id) {
                item = {
                    ...item,
                    queItemClicked: !item.queItemClicked
                }
            }
            // console.log(item);
            return item;
        })
        dispatch(CI_InitialStateAction('clicked_question', { questionSetId: (questionSetSelection.id || selectedQuestionSetId), id: id, showTime_updatedQuestionList }));
    }
    /*
    * Sort the array of question set in descending order
    */
    function sortElements(array, constraint) {
        return array.sort((a, b) => {
            if (a.questionsetName && b.questionsetName) {
                if (a[constraint] < b[constraint]) return 1;
                else if (a[constraint] > b[constraint]) return -1;
                return 0;
            }
        })
    }
    // useEffect(() => {
    //     setFilteredDataArr(filterDataList('questionsetName', [...sortedQuestionSets], searchFilterText))
    // }, [questionSet])
    useEffect(() => {
        if (filterListTypeRef.current === 'question-set-list') {
            if (searchFilterText.queSet == '') setFilteredDataArr(sortedQuestionSets);
            handleSearchChange('questionsetName')
        }
        else {
            if (searchFilterText.ques == '') setFilteredQuestionDataArr(filterDataList('queName', [...questionsList], searchFilterText.ques));
            handleSearchChange('queName')
        }
        // delayExpensiveTask(handleSearchChange, 3000);
    }, [searchFilterText])
    function delayExpensiveTask(state, listType, value, delay) {
        clearTimeout(deobunceIntervalId.current);
        deobunceIntervalId.current = setTimeout(() => {
            state(state => {
                if (listType === 'question-set-list') {
                    return {
                        ...state,
                        queSet: value
                    }
                }
                return {
                    ...state,
                    ques: value
                }
            });
        }, delay);
    }
    // if(FilteredDataArr.length){
    //     sortedQuestionSets = FilteredDataArr;
    //     console.log(FilteredDataArr, sortedQuestionSets);
    // }
    // Delete question(s) 
    const handleDelete = (id, listItemtype) => {
        if (listItemtype === 'questionnaire') {
            setDeleteLoader(state => ({ ...state, queSet: [...state.queSet, id] }));
            deleteQuestionnaire(accessToken, id, userInfo.userName)
                .then((res) => {
                    if (res.data.status === 'succeed') {
                        setAlertMessage({ display: res.data.status == 'succeed' ? true : false, text: 'Deleted successfully' });
                        closeAlert()
                        dispatch(CI_InitialStateAction('remove_questionnaire_from_questionset_list', { questionSetId: id }));
                        setDeleteLoader(state => {
                            let arr = [...state.queSet];
                            arr.splice(arr.indexOf(id), 1)
                            return {
                                ...state,
                                queSet: arr
                            }
                        });
                        setOpen(false);
                        deleteId.current = { id: '', type: '' }
                    }
                })
                .catch((err) => console.log(err));
            return;
        }
        function closeAlert() {
            setTimeout(() => {
                setAlertMessage({ display: false, text: '' })
            }, 2000);
        };
        // dispatch(CI_InitialStateAction('remove_question_from_questionset', { questionSetId: questionSetSelection.id, questionId: id, questionsList}));
        setDeleteLoader(state => ({ ...state, que: [...state.que, id] }));
        const questionnaireIdToDeleteQuestion = (questionSetSelection.id || selectedQuestionSetId);
        deleteQuestion(accessToken, id, questionnaireIdToDeleteQuestion, userInfo.userName)
            .then((res) => {
                //console.log(res);
                if (res.data.status === "succeed") {
                    setAlertMessage({ display: res.data.status == 'succeed' ? true : false, text: 'Deleted successfully' });
                    closeAlert()
                    dispatch(CI_InitialStateAction('remove_question_from_questionset', { questionSetId: questionSetSelection.id, questionId: id, questionsList }));
                    setDeleteLoader(state => {
                        let arr = [...state.que];
                        arr.splice(arr.indexOf(id), 1)
                        return {
                            ...state,
                            que: arr
                        }
                    });
                    setOpen(false);
                    deleteId.current = { id: '', type: '' };
                    setOrderQuestionsAfterDelete({ callQuestionOrderUpdateAPI: true });
                }
            })
            .catch((err) => console.log(err))
    }
    const handelEdit = (id, list) => {
        // setDeleteLoader(state => ({ ...state, que: [...state.que, id] }));
        setQuestionUpdated(prevState => ({ ...prevState, textStatus: 'loading', textUpdate: '' }));
        const updatedList = list.map((question, index) => {
            if (question.id === id) {
                question.queName = editQuestions;
            }
            return question;
        })
        // dispatch(CI_InitialStateAction('question_editor_save_update', { questionSetId: (questionSetSelection.id || selectedQuestionSetId), questionsList: updatedList }));
        // console.log(updatedList);
        // return   
        editQuestion(accessToken, id, userInfo.userName, editQuestions)
            .then((res) => {
                if (res.data.status === 'succeed') {
                    setQuestionUpdated(prevState => ({ ...prevState, textStatus: 'updated', textUpdate: 'Question updated successfully' }));
                    dispatch(CI_InitialStateAction('question_editor_save_update', { questionSetId: (questionSetSelection.id || selectedQuestionSetId), questionsList: updatedList }));
                    setTimeout(() => {
                        setQuestionUpdated(prevState => ({ ...prevState, textStatus: 'idle', textUpdate: '' }));
                    }, 3000)
                    setIsEdited(false);
                    setTimeout(() => {
                        list.map((question, index) => {
                            if (question.id === id) {
                                question.isEditing = false;
                            }
                        });
                    }, 1);
                }
                else {
                    setQuestionUpdated(prevState => ({ ...prevState, textStatus: 'error', textUpdate: 'Unable to update!' }));
                }
                // console.log(res);
            })
            .catch((err) => {
                console.log('err', err);
                setQuestionUpdated(prevState => ({ ...prevState, textStatus: 'error', textUpdate: 'Unable to update(Api error)!' }));
                setIsEdited(false);
                list.map((question, index) => {
                    if (question.id === id) {
                        question.isEditing = false;
                    }
                });
            })
    };
    const handleInputChange = (e, p1, p2) => {
        if (e.target.value !== '') {
            setEditQuestions(e.target.value);
            setIsFieldEmpty(false);
        }
        else {
            setIsFieldEmpty(true);
            setShowError({ display: 'true', text: "This field can't be empty" })
        }
    }
    // useEffect(()=>{
    //     if(accessToken, id){
    //     editQuestion(accessToken, id, userInfo.userName)
    //     }
    // },[accessToken, id]);
    const handleSearchChange = (listName) => {
        let array = [...sortedQuestionSets];
        if (listName === 'queName') {
            array = [...questionsList];
            setFilteredQuestionDataArr(filterDataList(listName, array, searchFilterText.ques));
        }
        else {
            setFilteredDataArr(filterDataList(listName, array, searchFilterText.queSet));
        }
    }
    const handleCheckedQuestion = (id) => {
        console.log(id);
        //dispatch(CI_InitialStateAction('set_selected_questions', {id, setId: questionSetSelection.id}));
    }
    const handleFilterSearch = (value, listType) => {
        filterListTypeRef.current = listType;
        delayExpensiveTask(setSearchFilterText, listType, value, 500);
    }
    const handleEditingInput = (id, setType, list, listItemName) => {
        setEditQuestions(listItemName)
        const updatedList = list.map((question, index) => {
            if (question.id === id) {
                question.isEditing = !question.isEditing;
                setIsEdited(true);
            }
            else {
                question.isEditing = false;
            }
            return question;
        })
        // console.log(updatedList);
        dispatch(CI_InitialStateAction('question_editor_enable_disable', { questionSetId: (questionSetSelection.id || selectedQuestionSetId), questionsList: updatedList }));
    };
    // const handleChange = (value, questionId) => {
    //     console.log(value);
    //     setUpdatedTimeData({ value: value, id: questionId })
    // };
    const handleChange = (e, questionId) => {
        const { name, value } = e.target;
        const array = [...questionTimes].map(item => {
            if (questionId === item.id && !isNaN(value) && value !== '') {
                item[name] = value ? parseInt(value) : 0;
            }
            else {
                item[name] = 0;
            }
            return item;
        })
        setQuestionTimes(array)
        setUpdatedTimeData(preState =>
        (
            {
                ...preState,
                [name]: value ? parseInt(value) : 0, id: questionId
            }
        ));
    };
    // console.log('dfbsfb', updatedTimeData);
    //console.log('questionTimes.current', questionTimes);
    function updateQueTime(id, list) {
        let status = 1;
        if ((Number(updatedTimeData.thinkTime) > Number(updatedTimeData.timeLimit))) {
            status = 0;
            setShowError({ display: true, text: 'Think time should be less then time limit' })
            setTimeout(() => {
                setShowError({ display: false, text: '' })
            }, 2000);
        };
        if ((Number(updatedTimeData.thinkTime) === 0 || Number(updatedTimeData.timeLimit) === 0)) {
            status = 0;
            setShowError({ display: true, text: 'Zero value is not allowed' })
            setTimeout(() => {
                setShowError({ display: false, text: '' })
            }, 2000);
        };
        if (status) {
            setQuestionUpdated(prevState => ({ ...prevState, timeStatus: 'loading', timeUpdate: '' }));
            updateTime(accessToken, updatedTimeData, selectedQuestionSetId, userInfo.userName)
                .then(res => {
                    if (res.data.status === 'succeed') {
                        const updatedList = list.map((question, index) => {
                            if (question.id === id) {
                            //    console.log(question, 'questiondata');
                                question.thinkTime = parseInt(updatedTimeData.thinkTime);
                                question.timeLimit = parseInt(updatedTimeData.timeLimit);
                                question.isTimeEditing = false;
                            }
                            return question;
                        })
                        dispatch(CI_InitialStateAction('question_editor_save_updated_time', { questionSetId: (questionSetSelection.id || selectedQuestionSetId), questionsList: updatedList }));
                        setQuestionUpdated(prevState => ({ ...prevState, timeStatus: 'updated', timeUpdate: 'Question time updated successfully' }));
                        setTimeout(() => {
                            setQuestionUpdated(prevState => ({ ...prevState, timeStatus: 'idle', timeUpdate: '' }));
                        }, 3000)
                    }
                })
                .catch(err => {
                    console.log(err);
                    setQuestionUpdated(prevState => ({ ...prevState, timeStatus: 'error', timeUpdate: 'Unable to update!' }));
                })
        }

    };
    function handleTimerEdit(list, questionId) {
        const updatedList = list.map((question, index) => {
            if (question.id === questionId) {
                question.isTimeEditing = !question.isTimeEditing;
            }
            else {
                question.isTimeEditing = false;
            }
            return question;
        })
        dispatch(CI_InitialStateAction('question_editor_timeUpdate_enable_disable', { questionSetId: (questionSetSelection.id || selectedQuestionSetId), questionsList: updatedList }));
    }
    // console.log(tableLoaderErrInfo, 'tableLoaderErrInfo');
    return (
        <>

            <div>
                <div style={{ color: "rgb(74, 74, 74)" }}>
                    <h1 style={{ marginTop: '-12px' }} className='interview-build-heading'>Build Your Interview</h1>
                </div>
                {/* <hr /> */}
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Alert"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button color='success' onClick={() => {
                        handleDelete(deleteId.current.id, deleteId.current.type)
                    }} >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid container spacing={2}
                sx={{
                    display: 'flex',
                    //     // flexDirection: 'row',
                    justifyContent: 'space-between',
                    //     width: 'auto',
                    //     height: '68vh',
                    //     boxShadow: 4,
                    //     overflow: 'hidden'
                    marginTop: '3px'
                }}
            // className='question-set-container'
            >
                <Grid item xs={12} md={5} className='question-box' >
                    <Box sx={{
                        display: ' inlineflex', justifyContent: 'space-between', paddingTop: '10px', paddingRight: '5px',
                        paddingBottom: '7px', backgroundColor: '#c4c4c4'
                    }}>
                        <div ref={leftboxEl} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ padding: '0 15px' }}>
                                <h2 className='text_h2'>Question set</h2>
                            </div>
                            <AddQuestionnaires />
                        </div>
                        <div className='search-bar-add-que'>
                            <Box
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '90%', height: '42px', borderRadius: '25px', boxShadow: 5, }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1, width: 'auto' }}
                                    placeholder="Search"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e) => handleFilterSearch(e.target.value, 'question-set-list')}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                    <IconButton sx={{ p: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} aria-label="search">
                                        <SearchIcon className='search-icon-rm' sx={{ color: '#0aa699' }} />
                                    </IconButton>
                                    <Divider sx={{ height: 28, m: 0.5, position: 'absolute', left: 0 }} orientation="vertical" />
                                </div>
                            </Box>
                        </div>
                    </Box>

                    <div className='left-box-scrollable-child'>
                        {
                            FilteredDataArr.map((questionnaire, index) => {
                                // console.log(questionnaire, 'questionnaire', questionnaire.createdBy, userInfo.userName);
                                let elementSelected = (questionSetSelection.id || selectedQuestionSetId) === questionnaire.questionsetId
                                return <Box key={questionnaire.questionsetId} className='QuestionnairiesBox' sx={{ display: 'flex' }}>
                                    <div className='question-box-wrapper' onClick={() => handleQuestionSetSelection(questionnaire.questionsetId, index)}>
                                        <div className='question-box-left'>
                                            {elementSelected ? <RemoveIcon color='primary' /> : <AddIcon />}
                                        </div>
                                        <div className='question-box-right'>
                                            <h4 style={{ color: elementSelected ? '#1976d2' : '' }}> {questionnaire.questionsetName}</h4>
                                        </div>
                                    </div>
                                    {
                                        (questionnaire.createdBy === userInfo.userName || userRole === isAdmin) && <div className='questionnarire-delete'>
                                            {
                                                deleteLoader.queSet.includes(questionnaire.questionsetId) ? <CircularProgress size={18} sx={{ marginLeft: '10px', color: '#01579b' }} />
                                                    :
                                                    <Tooltip title='Delete' placement="top"><span><IconButton onClick={() => {
                                                        handleClickOpen(questionnaire.questionsetId, 'questionnaire');
                                                    }}>  <DeleteOutlineIcon sx={{ color: '#ff4040' }} className='c-pointer' /></IconButton></span></Tooltip>
                                            }
                                        </div>
                                    }
                                </Box>
                            })
                        }
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {(!sortedQuestionSets.length && tableLoaderErrInfo.questionset == '') && <Loader className='preloader-icon' />}
                            {tableLoaderErrInfo.questionset}
                        </Box>
                    </div>
                </Grid>
                <Grid item xs={12} md={6.8} className='question-box'>
                    <Box sx={{
                        display: 'inlineflex', justifyContent: 'space-between', paddingTop: '10px', paddingRight: '5px',
                        backgroundColor: '#c4c4c4', width: '100%', paddingBottom: '10px'
                    }}>
                        <div className='search-bar-add-que ques-list'>
                            <Box
                                component="form"
                                sx={{ ml: 2, p: '2px 4px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '90%', height: '42px', borderRadius: '4px', boxShadow: 5, background: 'white' }}
                            >
                                <InputBase
                                    sx={{ ml: 2, flex: 1, width: '100px' }}
                                    placeholder="Type Your Question Here"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e) => {
                                        inputSearchQuestion.current = e.target.value;
                                        handleFilterSearch(e.target.value, 'question-list');
                                    }}
                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                />
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                    <IconButton sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon className='search-icon-rm' />
                                    </IconButton>
                                    <Divider sx={{ height: 28, m: 0.5, position: 'absolute', left: 0 }} orientation="vertical" />
                                </div>
                            </Box>
                            <AddQuestion questionSetId={questionSetSelection.id || selectedQuestionSetId} questionsList={questionsList} />
                        </div>

                    </Box>
                    <div className='left-box-scrollable-child' id='question-list-scroll-view'>
                        <DragDropContext onDragEnd={(e) => handleDrop(e)}>
                            <Droppable droppableId="list">
                                {provided => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            FilteredQuestionDataArr.map((item, index) => {
                                                // console.log(item);
                                                let checked = selectedQuestions.includes(item.id)
                                                let questionTimesBool = questionTimes[index]
                                                return item.queName && <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(draggableProvided, draggableSnapshot) => (
                                                        <div
                                                            ref={draggableProvided.innerRef}
                                                            {...draggableProvided.draggableProps}
                                                            {...draggableProvided.dragHandleProps}

                                                        >
                                                            <Box
                                                                className={item.isEditing ? 'QuestionBox enabled-editing-border' : 'QuestionBox'}
                                                                key={item.queName + index}
                                                            // draggable={true}
                                                            // onDragStart={(e) => handleDragStart(e, index)}
                                                            // onDragEnter={(e) => handleDragEnter(e, index)}
                                                            // onDragEnd={handleDrop}
                                                            // onDragLeave={(e) => handleDragLeave(e, index)}
                                                            // onDragOver={(e) => handleDragOver(e, index)}
                                                            >
                                                                <ListItem sx={{ padding: '0 5px', display: 'flex', flexDirection: 'column' }} >
                                                                    <div className='QuestionBox-inner'>
                                                                        <div className='QuestionBox-inner-left'>
                                                                            {/* <Checkbox
                                                    edge="start"
                                                    tabIndex={-1}
                                                    disableRipple
                                                    sx={{ padding: '0 0 0 21px' }}
                                                    checked={checked}
                                                // inputProps={{ 'aria-labelledby': labelId }}
                                                    onChange={() => handleCheckedQuestion(item.id)}
                                                /> */}
                                                                            <div className='question-text'>{
                                                                                item.isEditing ?
                                                                                    <>
                                                                                        <span className='ques-count'></span>
                                                                                        <Box className='input-box-editing'>
                                                                                            <form style={{ display: 'flex' }}>
                                                                                                <h5 style={{ marginTop: ' auto' }}>{index + 1}. &nbsp;</h5>
                                                                                                <textarea type='text' onChange={(e) => handleInputChange(e, item.id, [...FilteredQuestionDataArr])} defaultValue={item.queName} style={{ width: 'auto', height: '50px', fontSize: '18px', fontWeight: 'bold' }} />
                                                                                                {
                                                                                                    questionUpdated.textStatus === 'loading' ? <CircularProgress size={18} sx={{ marginLeft: '10px', color: '#01579b' }} /> :
                                                                                                        <>  {
                                                                                                            < IconButton type='button' onClick={() => handelEdit(item.id, [...FilteredQuestionDataArr])}>
                                                                                                                {(isEdited && !isFieldEmpty) ? <CheckIcon sx={{ marginTop: '-25px' }} /> : ''} </IconButton>
                                                                                                        }
                                                                                                        </>
                                                                                                }
                                                                                            </form>
                                                                                        </Box>
                                                                                        {
                                                                                            questionUpdated.textStatus === 'updated' &&
                                                                                            <DirectionSnackbar snackbarDisplay={true} message={questionUpdated.textUpdate} className={'question-update-snackbar'} templateUpdater={null} />
                                                                                        }
                                                                                        {
                                                                                            questionUpdated.textStatus === 'error' &&
                                                                                            <DirectionSnackbar snackbarDisplay={true} message={questionUpdated.textUpdate} className={'question-update-error-snackbar'} templateUpdater={null} />
                                                                                        }
                                                                                    </>
                                                                                    : <><span className='ques-count'></span> <h5>{index + 1}. &nbsp;{item.queName}</h5>
                                                                                    </>
                                                                            }</div>
                                                                        </div>
                                                                        <div className='QuestionBox-inner-right'>
                                                                            <div className='qi-right-utility'>
                                                                                <Tooltip title='View Time' placement="top"><span><IconButton onClick={() => handleTimerPanel(item.id)}> <AccessTimeIcon className='c-pointer' /></IconButton></span></Tooltip>
                                                                            </div>
                                                                            <div className='qi-right-utility'>
                                                                                {
                                                                                    <Tooltip title='Edit Question' placement="top"><span><IconButton
                                                                                        onClick={() => handleEditingInput(item.id, 'question', [...FilteredQuestionDataArr], item.queName)}
                                                                                    ><ModeEditIcon sx={{ color: '#1976d2' }} className='c-pointer' /></IconButton></span></Tooltip>
                                                                                }
                                                                            </div>

                                                                            <div className='qi-right-utility'>

                                                                                {
                                                                                    deleteLoader.que.includes(item.id) ?
                                                                                        <CircularProgress size={18} sx={{ marginLeft: '10px', color: '#01579b' }} />
                                                                                        : <span><IconButton onClick={() => handleClickOpen(item.id, 'question')}><Tooltip title='Delete' placement="top"><DeleteOutlineIcon sx={{ color: '#ff4040' }} className='c-pointer' /></Tooltip></IconButton></span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        item.queItemClicked && <>
                                                                            <div className='question-box-divider'></div>
                                                                            <div className='bottom-timer-panel'>

                                                                                <div className='bt-panel-inner'>
                                                                                    <div className='bt-panel-inner-child'>
                                                                                        Time Limit
                                                                                    </div>
                                                                                    {
                                                                                        !item.isTimeEditing
                                                                                            ?
                                                                                            <div className='bt-panel-inner-child time'>
                                                                                                {item.timeLimit} Sec
                                                                                            </div>
                                                                                            :
                                                                                            <div className='bt-panel-inner-child time'>
                                                                                                <input style={{ width: '33%', fontWeight: '500', background: '#ebebeb42', fontSize: '16px', color: '#7f7f7f' }}
                                                                                                    value={questionTimesBool ? questionTimes[index].timeLimit : 0}
                                                                                                    min="0"
                                                                                                    pattern="[0-9]{3}"
                                                                                                    placeholder="0"
                                                                                                    onFocus={(e) => e.target.style.border = '2px solid #1976d2'}
                                                                                                    onBlur={(e) => e.target.style.borderColor = '#817b7b'}
                                                                                                    maxLength={3}
                                                                                                    name='timeLimit'
                                                                                                    onInput={(e) => { handleChange(e, item.id) }} />Sec
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                                <div className='bt-panel-inner'>
                                                                                    <div className='bt-panel-inner-child'>
                                                                                        Think Time
                                                                                    </div>
                                                                                    {
                                                                                        !item.isTimeEditing ?
                                                                                            <div className='bt-panel-inner-child time'>
                                                                                                {item.thinkTime} Sec
                                                                                            </div>
                                                                                            :
                                                                                            <div className='bt-panel-inner-child time'>
                                                                                                <input style={{ width: '33%', fontWeight: '500', background: '#ebebeb42', fontSize: '16px', color: '#7f7f7f', borderColor: '#1976d2' }}
                                                                                                    value={questionTimesBool ? questionTimes[index].thinkTime : 0}
                                                                                                    pattern="[0-9]*"
                                                                                                    onFocus={(e) => e.target.style.border = '2px solid #1976d2'}
                                                                                                    onBlur={(e) => e.target.style.borderColor = '#817b7b'}
                                                                                                    placeholder="0"
                                                                                                    maxLength={3}
                                                                                                    name='thinkTime'
                                                                                                    // onChange={date => handleChange({ target: { value: date, name: 'startDate' } })}
                                                                                                    onInput={(e) => handleChange(e, item.id)} />Sec
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    questionUpdated.timeStatus === 'loading'
                                                                                        ? <CircularProgress size={18} sx={{ marginLeft: '10px', color: '#01579b' }} />
                                                                                        :
                                                                                        <> {(item.isTimeEditing) && <Tooltip title='Save' placement="top"><span><IconButton
                                                                                            //  disabled={!item.queItemClicked || !item.isTimeEditing} 
                                                                                            sx={{ marginLeft: '30px' }} onClick={() => { updateQueTime(item.id, [...FilteredQuestionDataArr]) }}><CheckIcon /></IconButton></span></Tooltip>
                                                                                        }
                                                                                        </>
                                                                                }
                                                                                <Tooltip title='Edit Time' placement="top"><span><IconButton onClick={() => {
                                                                                    handleTimerEdit([...FilteredQuestionDataArr], item.id)
                                                                                    setEditTime(!editTime);
                                                                                    setUpdatedTimeData({ thinkTime: item.thinkTime, timeLimit: item.timeLimit, id: item.id })
                                                                                }}
                                                                                ><ModeEditIcon className='c-pointer' sx={item.isTimeEditing ? { color: '#1976d2' } : { color: 'grey' }} /></IconButton></span></Tooltip>
                                                                                {
                                                                                    questionUpdated.timeStatus === 'updated' &&
                                                                                    <DirectionSnackbar snackbarDisplay={true} message={questionUpdated.timeUpdate} className={'question-update-snackbar'} templateUpdater={null} />
                                                                                }
                                                                                {
                                                                                    questionUpdated.timeStatus === 'error' &&
                                                                                    <DirectionSnackbar snackbarDisplay={true} message={questionUpdated.timeUpdate} className={'question-update-error-snackbar'} templateUpdater={null} />
                                                                                }
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </ListItem>
                                                            </Box>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            })
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        {
                            questionSetSelection.id && tableLoaderErrInfo.questions === 'loading' ? <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {(tableLoaderErrInfo.questions === 'loading') && <Loader sx={{ height: '67vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />}
                                {/* {tableLoaderErrInfo.questions !== 'loading' && tableLoaderErrInfo.questions} */}
                            </Box>
                                :
                                <> {
                                    (tableLoaderErrInfo.questions !== 'loading' && tableLoaderErrInfo.questions) !== 'No record found' ?
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {tableLoaderErrInfo.questions !== 'loading' && tableLoaderErrInfo.questions}
                                        </Box>
                                        :
                                        <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {tableLoaderErrInfo.questions !== 'loading' && tableLoaderErrInfo.questions}
                                        </Box>
                                }
                                </>
                        }
                        <SuccessAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                        <ErrorAlert showError={showError} setShowError={setShowError} />
                    </div>
                </Grid>
            </Grid >
        </>
    )
}
export default Questionnaires;