import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import './index.css';
import { Dialog } from '@mui/material';
import { useEffect, useRef } from 'react';


export const ErrorAlert = (props) => {

    const { showError, setShowError } = props;

    function handleClose() {
        setShowError({ display: false, text: '' })
    }
    const ref = useRef();
    useOnClickOutside(ref, () => setShowError({ display: false, text: '' }));
    console.log(showError);
    return (
        <>
            {showError.display && <div ref={ref} className='disp-error-alert success-alert'>
                <Stack sx={{ width: '50%' }} spacing={2}>
                    <div className='disp-error-alert-inner'>
                        <Alert severity="error">{showError.text}</Alert>
                        <div className='close-icon-err-alert'>
                            <CloseIcon onClick={handleClose} sx={{ color: '#939393' }} />
                        </div>
                    </div>
                </Stack>
            </div>}

        </>

    )
}



function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        [ref, handler]
    );
}

// export const ErrorAlertMultiline = (props) => {

//     const { errorContainer, showErrorMultiline, setShowErrorMultiline } = props;

//     function handleClose() {
//         setShowErrorMultiline(false)
//     }

//     return (

//         <>
//             {
//                 showErrorMultiline && <div className='disp-error-alert success-alert'>
//                     <Stack sx={{ width: '50%' }} spacing={2}>
//                         <div className='disp-error-alert-inner'>
//                             <Alert severity="error">
//                                 <ul>
//                                     {
//                                         errorContainer.map((error, index) => {
//                                             return <li key={index}>{error}</li>
//                                         })
//                                     }
//                                 </ul>
//                             </Alert>
//                             <div className='close-icon-err-alert'>
//                                 <CloseIcon onClick={handleClose} sx={{ color: '#939393' }} />
//                             </div>
//                         </div>
//                     </Stack>
//                 </div>}
//         </>

//     )
// }