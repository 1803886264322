import axios from 'axios';
import AppConstants from '../Constants';
import { createStore } from 'redux';

//start
//storage for api rate limit
let frequentErrorsCount = [];
const errorsCodes = [0, 400, 401, 403, 408, 404, 405, 500, 501, 502, 503, 504];
//end
//storage for api rate limit


function accessTokenReducer(state = { token_status: 'idle', previous_status: '' }, action) {
    switch (action.type) {
        case 'accessTokenStatus':
            let previous_status = state.previous_status;
            if (action.payload != action.payload) {
                previous_status = action.payload;

            }
            return { token_status: action.payload, previous_status: previous_status }
        default:
            return state
    }
}

export let accessTokenStore = createStore(accessTokenReducer, 'idle');


function verifyAccessTokenAction(token) {
    return {
        type: 'accessTokenStatus',
        payload: token
    }
}


const responseSucces = (response) => ({
    status: true,
    message: response,
    data: response.data,
});

const responseFail = (error) => {
    //debugger
    console.log(error, error.response.data !== undefined, 'reserror');

    let errorResponseText = undefined;
    if (error.response.data !== undefined) {
        errorResponseText = error.response.data.error;
    }

    // start
    //check if same error code appears multiple time, limit the api hit request
    if (errorsCodes.includes(error.response.status)) {
        frequentErrorsCount.push(error.response.status);
        const frequencies = frequentErrorsCount.filter(errorCode => errorCode === error.response.status);
        if (frequencies.length < 4) {
            accessTokenStore.dispatch(verifyAccessTokenAction({ statusCode: error.response.status, statusText: errorResponseText }))
        }
        else {
            setTimeout(() => {
                frequentErrorsCount = [];
            }, 1000 * 60 * 5);
        }
    }
    //end
    // check if same error code appears multiple time, limit the api hit request


    // error.response.data.error 



    if (error.response.data) {
        if (error.response.data.error === "invalid_token") {
            // alert('Token Expired')
        }
    }
    if (error.response.status === 0 || error.response.status === 401) {
        // window.location.href = `https://${environment.socialRedirect_URI}/login?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}`
    }

    console.log(error);
    return {
        status: false,
        code: error.response.status,
        message: error.message,
    }


}

export const AxiosInstance = createAxiosInstance('API_PATH');
AxiosInstance.interceptors.response.use(responseSucces, responseFail);

export const AxiosInstanceCds = createAxiosInstanceCds('API_PATH_CDS');
AxiosInstanceCds.interceptors.response.use(responseSucces, responseFail);

export const AxiosInstanceAws = createAxiosInstanceAws('AWS_COGNITO');
AxiosInstanceAws.interceptors.response.use(responseSucces, responseFail);

export const AxiosInstanceMail = createAxiosInstanceMail(' MAIL_PATH');
AxiosInstanceMail.interceptors.response.use(responseSucces, responseFail);

export const AxiosInstanceRun = createAxiosInstanceRun('RUN_API');
AxiosInstanceRun.interceptors.response.use(responseSucces, responseFail);

export const AxiosInstanceCes = createAxiosInstanceCes('CES_API');
AxiosInstanceCes.interceptors.response.use(responseSucces, responseFail);

export const AxiosInstanceAzureMedia = createAxiosInstanceAzureMedia('AZURE_MEIDA');
AxiosInstanceAzureMedia.interceptors.response.use(responseSucces, responseFail)


export const AxiosInstanceRefresTokenCup = createAxiosInstance('REFRESH_TOKEN_CUP');
AxiosInstance.interceptors.response.use(responseSucces, responseFail);



function createAxiosInstanceCes(path) {
    return axios.create({
        baseURL: AppConstants.serverConstants[path],
        timeout: -1,
        headers: {
            "content-type": "application/json",
        },
    });
};

function createAxiosInstanceRun(path) {
    return axios.create({
        baseURL: AppConstants.serverConstants[path],
        timeout: -1,
        headers: {
            "content-type": "application/json",
        },
    });
};

function createAxiosInstanceMail(path) {
    return axios.create({
        baseURL: AppConstants.serverConstants[path],
        timeout: -1,
        headers: {
            "content-type": "application/json",
        },
    });
};

function createAxiosInstanceCds(path) {
    console.log(AppConstants.serverConstants);
    return axios.create({
        baseURL: AppConstants.serverConstants[path],
        timeout: -1,
        headers: {
            "content-type": "application/json",
        },
    });
};

function createAxiosInstanceAws(path) {
    return axios.create({
        baseURL: AppConstants.serverConstants[path],
        timeout: -1,
        headers: {
            "content-type": "application/x-www-form-urlencoded",
        },
    });
};

function createAxiosInstance(path) {
    return axios.create({
        baseURL: AppConstants.serverConstants[path],
        timeout: -1,
        headers: {
            "content-type": "application/json",
        },
    });
}

function createAxiosInstanceAzureMedia(path) {
    return axios.create({
        baseURL: AppConstants.serverConstants[path],
        timeout: -1,
        headers: {
            "content-type": "video/mp4",
        },
    })
}
