import { minHeight } from "@mui/system";
import { type } from "@testing-library/user-event/dist/type";
import InterviewList from "../Components/Interview/InterviewList/InterviewList";
import AppConstants from "../Constants";
import {
  AxiosInstance,
  AxiosInstanceCds,
  AxiosInstanceCes,
} from "./http.handler";

export const GridCustomization = async (accessToken, userName, columndata,moduleName) => {
  console.log(userName ,"columndata");
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  const data = JSON.stringify({
    userName: userName,
    customization: {
      columns: columndata.columns,
      allowedPageSizes: columndata.allowedPageSizes,
      filterPanel:columndata.filterPanel,
      filterValue:columndata.filterValue,
      searchText: columndata.searchText,
      pageIndex:columndata.pageIndex,
      pageSize: columndata.pageSize,
    },
    module: moduleName,
  });
  return await AxiosInstance.post(
    `${
      AppConstants.serverConstants.API_PATH +
      "/" +
      AppConstants.serverConstants.API_ENDPOINTS.GRID_CUSTOMIZE
    }?source=videoInterview`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const GridCustomizeGet = async (accessToken, userName,moduleName) => {
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  return await AxiosInstance.get(
    `${
      AppConstants.serverConstants.API_PATH +
      "/" +
      AppConstants.serverConstants.API_ENDPOINTS.GRID_CUSTOMIZE_GET
    }?module=${moduleName}&userName=${userName}&source=videoInterview`,

    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
