import { minHeight } from "@mui/system";
import { type } from "@testing-library/user-event/dist/type";
import AppConstants from "../Constants";
import {
  AxiosInstance,
  AxiosInstanceCds,
  AxiosInstanceCes,
} from "./http.handler";

export async function handleJobs(
  reqType,
  accessToken,
  username = "",
  jobId = ""
) {
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  let urlpart = "";
  if (reqType === "getJobs") {
    urlpart = `/assignJob?order=asc&source=VideoInterview`;
  } else if (reqType === "getAttachedCandidates") {
    urlpart = `/suggestCandidate/${jobId}?source=videoInterview`;
    // return await GetCandidateCes(accessToken,jobId);
  }
  return await AxiosInstance.get(
    `${AppConstants.serverConstants.API_PATH}${urlpart}`,
    { headers: headers }
  );
}
export async function GetJobs(accessToken) {
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  return await AxiosInstance.get(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.GET_ATS_JOB}?user=tester&source=videoInterview`,
    {
      headers: headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
}

export async function GetCDSJobs(accessToken, userInfo, userRole) {
  // console.log(userRole, 'hhhkjh');
  let isAdmin = '';
  if (userRole === "Global Administrator" || userRole === "VideoInterview Administrator") {
    isAdmin = 'Global Administrator';
  }
  else {
    isAdmin = 'OWNER';
  }
  let userName = "";
  if (Object.keys(userInfo).length) {
    userName = userInfo.email.substring(0, userInfo.email.indexOf("@"));
  };
  const data = JSON.stringify({
    "username": userName,
    "actionPermission": isAdmin
  })
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  return await AxiosInstanceCds.post(
    `${AppConstants.serverConstants.CDS.GET_ATS_JOB_CDS}?source=test`,
    data,
    {
      headers: headers,
    }
  )
    .then((response) => response)
    .catch((error) => error);
}

export const GetCandidateCes = async (accessToken, jobId) => {
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  const data = JSON.stringify({
    request: [
      {
        attached: [
          {
            "jobId.keyword": jobId,
            key: "equal",
          },
          {
            deleted: "1",
            key: "notequal",
          },
          {
            sort: ["lastModified", "desc"],
          },
        ],
      },
    ],
  });
  return await AxiosInstanceCes.post(
    `${AppConstants.serverConstants.CES.GET_CANDIDATE}/attached/candidate?relation=objectId&perpage=1000&next=1&source=source=videoInterview`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export async function CreateLocalJob(accessToken, jobtype, localjob, userInfo, userRole) {
  debugger
  let isAdmin = false;
  if (userRole === "Global Administrator" || userRole === "VideoInterview Administrator") {
    isAdmin = true;
  }
  else {
    isAdmin = false;
  }


  let userName = "";
  if (Object.keys(userInfo).length) {
    userName = userInfo.email.substring(0, userInfo.email.indexOf("@"));
  }

  let status = 1;

  for (const key in localjob) {
    console.log(localjob[key]);
    if (localjob[key] === "") {
      status = 0;
      break;
    }
  }
  if (!status) {
    return;
  }
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  let data = "";
  if (jobtype) {
    data = [...localjob].map((jobItem, index) => {
      console.log(jobItem);
      let payload =
      {
        jobName: jobItem["Job Title"],
        recruiterMobileNumber: jobItem["mobile number"],
        skills: [jobItem["skills"]],
        jobId: jobItem.jobId,
        jobStatus: jobItem.status,
        ctc: [
          {
            ctcfrom: jobItem['CTC from'],
            ctcto: jobItem['CTC to'],
          },
        ],
        atsId: "",
        recruiterEmailId: userInfo.email,
        jobCode: jobItem["Job Code"],
        clientId: jobItem['client Id'],
        description: jobItem.description,
        createdBy: userName,
        isLocal: false,
        location: jobItem.Location,
        designation: jobItem.Designation,
        createdAt: new Date().toISOString(),
        experience: [
          {
            experienceFrom: jobItem['Experience from'].toString(),
            experienceTo: jobItem['Experience to'].toString(),
          },
        ],
        securitygroupuser: jobItem.securitygroupuser,
        isAdmin: isAdmin
      };
      if (jobItem.jobclosedate !== null) {
        payload = { ...payload, expiredDate: new Date(jobItem.jobclosedate).toISOString() };
      }
      else {
        payload = { ...payload }
      }
      return payload
    });
    data = JSON.stringify(data);
  } else {
    data = JSON.stringify({
      recruiterEmailId: userInfo.email,
      recruiterMobileNumber: localjob.number,
      jobName: localjob.jobtitle,
      jobStatus: "Active",
      designation: localjob.designation,
      skills: [localjob.skills],
      location: localjob.location,
      experience: [
        {
          experienceFrom: localjob.experience_from,
          experienceTo: localjob.experience_to,
        },
      ],
      ctc: [
        {
          ctcfrom: localjob.ctc_from,
          ctcto: localjob.ctc_to,
        },
      ],
      description: localjob.description,
      isLocal: true,
      deleted: false,
      createdBy: userName,
      createdAt: new Date().toISOString(),
      clientName: localjob.client_name,
      // clientId: localjob.client_id,
      "clientId": "63bd2f8323b2191b80e2d220",
      isAdmin: isAdmin
    });
  }

  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.CREATE_JOB}?source=videoInterview`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
}

export const AddCandidate = async (
  accessToken,
  formRows,
  selectedJobId,
  jobName,
  userName,
  isLocal
) => {
  // console.log(accessToken,
  //   formRows,
  //   selectedJobId,
  //   jobName,
  //   userName,
  //   isLocal, 'sdfhgsfag');
  const value = [...formRows].map((item, index) => {
    let FirstName = "";
    let lastName = "";
    if (isLocal) {
      item.CandidateName = item.CandidateName.replace(/\s{2,}/g, ' ')
      const name = item.CandidateName.split(" ");
      FirstName = name[0];
      for (let i = 1; i < name.length; i++) {
        lastName += name[i] + " ";
      }
    }
    let number = '';
    if (!isLocal) {

      if (item.candidate.mobileNumber) {
        number = item.candidate.mobileNumber
      }
      else {
        number = ''
      }
      if (item.candidate.last_name) {
        lastName = item.candidate.last_name
      }
      else {
        lastName = ''
      }

      // debugger;
      const namearray = item.candidate.name.split(" ");

      // console.log(namearray, 'namearray');
      namearray.forEach((element, index) => {
        if (index > 0) {
          if (index < namearray.length - 1) {
            lastName += " " + element;
          }
          else {
            lastName += element;
          }

        }
        else {
          FirstName = namearray[0];
        }
      });
    }
    return {
      jobId: selectedJobId,
      jobName: jobName,
      createdAt: new Date().toISOString(),
      createdBy: userName,
      mobileNumber: isLocal ? item.Number : number,
      email: isLocal ? item.Email : item.candidate.email,
      name: isLocal ? item.CandidateName : item.candidate.name,
      first_name: FirstName,
      last_name: lastName,
      contactName: isLocal ? item.CandidateName : item.candidate.name,
      clientId: "63bd2f8323b2191b80e2d220",
      clientName: "GlobalHunt India Pvt. Ltd.",
      attachedBy: userName,
      isChecked: isLocal ? false : true,
      mailSend: false,
      isLocal: true,
      deleted: false,
      atsCandidateId: !isLocal ? item.objectId : '',
    };
  });

  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  const data = JSON.stringify(value);

  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.ADD_CANDIDATE}?source=VideoInterviewAPI`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const ScheduleInterview = async (
  accessToken,
  data,
  interviewType,
  startDate,
  startTime
) => {
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  let startDateInfo = new Date(startDate).setHours(
    startTime.substring(0, 2),
    startTime.substring(3, 5)
  );

  console.log(data);
  const body = JSON.stringify([
    {
      jobId: data.jobId,
      jobCode: data.jobCode,
      jobName: data.jobName,
      createdAt: data.createdAt,
      createdBy: data.createdBy,
      interviewType: interviewType,
      deleted: data.deleted,
      startDate: new Date(startDateInfo).toISOString(),
    },
  ]);
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.SCHEDULE_INTERVIEW}?source=VideoInterviewAPI`,
    body,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
export const JobInterview = async (
  accessToken,
  selectedJob,
  scheduleInterviewId,
  selectedCandidates,
  userInfo,
  interviewTimeLineInfoState,
  time_duration,
  isLocalJob,
  timezone,
  recruiterEmail,
  isAdmin
) => {
  let timeDiff = {
    h1: interviewTimeLineInfoState.interviewTimeFrom.substring(0, 2),
    m1: interviewTimeLineInfoState.interviewTimeFrom.substring(3, 5),
    h2: interviewTimeLineInfoState.interviewTimeTo.substring(0, 2),
    m2: interviewTimeLineInfoState.interviewTimeTo.substring(3, 5),
  };
  let from = new Date(interviewTimeLineInfoState.interviewDateFrom).setHours(
    timeDiff.h1,
    timeDiff.m1
  );
  let to = new Date(interviewTimeLineInfoState.interviewDateTo).setHours(
    timeDiff.h2,
    timeDiff.m2
  );
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  let timearr = time_duration.split(" ");
  let time_in_minutes = 0;
  for (let i = 0; i < timearr.length; i++) {
    if (timearr[i + 1] === "Day") {
      time_in_minutes = (24 / parseInt(timearr[i])) * 60;
    } else if (timearr[i + 1] === "Hours") {
      time_in_minutes += parseInt(timearr[i]) * 60;
    } else if (timearr[i + 1] === "Minutes") {
      time_in_minutes += parseInt(timearr[i]);
    }
  }
  const candidateDetails = [...selectedCandidates].map((candidate, index) => {
    return {
      jobId: selectedJob.jobId,
      jobCode: selectedJob.jobCode,
      jobName: selectedJob.jobName,
      scheduleInterviewId: scheduleInterviewId,
      candidateName: isLocalJob
        ? candidate.attachCandidate[0].candidateFirstName +
        " " +
        candidate.attachCandidate[0].candidateLastName
        : candidate.candidate.name,
      candidateId: isLocalJob
        ? candidate.attachCandidate[0].candidateId
        : candidate.candidate.id,
      candidateEmail: isLocalJob
        ? candidate.attachCandidate[0].candidateEmail
        : candidate.candidate.email,
      candidateNumber: isLocalJob
        ? candidate.attachCandidate[0].candidateNumber
        : candidate.candidate.mobileNumber,
      attachedResumeId: isLocalJob
        ? candidate.attachedResumeId
        : candidate.candidate.id,
      clientName: candidate.clientName,
      clientId: candidate.clientId,
      stage: "pendingAtHR",
      status: "interviewPending",
      createdBy: userInfo,
      createdAt: new Date().toISOString(),
      deleted: false,
      isArchive: false,
      duration: time_duration,
      startDate: new Date(from).toISOString(),
      endDate: new Date(to).toISOString(),
      duration: time_in_minutes,
      isLocal: true,
      timeZone: timezone,
      isChecked: true,
      isAts: isLocalJob ? false : true,
      isAdmin: isAdmin,
      recruiterEmailId: recruiterEmail
    };
  });

  const data = JSON.stringify(candidateDetails);
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.JOB_INTERVIEW}?source=VideoInterviewAPI`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const JobInterviewID = async (
  accessToken,
  JobInterviewId,
  questionSetId,
  username,
  selectedQuestions
) => {
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  const data = JSON.stringify([
    {
      questionnairesId: questionSetId,
      stage: "questionnairesAssign",
      status: "interviewPending",
      updatedAt: new Date().toISOString(),
      updatedBy: username,
      candidateQuestions: selectedQuestions,
    },
  ]);
  return await AxiosInstance.put(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.JOB_INTERVIEWPUT}/${JobInterviewId}?source=VideoInterviewAPI`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};



export const JobInterviewIDApiMailErrorHandler = async (
  accessToken,
  JobInterviewID,
  username,
) => {
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  const data = JSON.stringify([
    {
      status: "Unsent",
      updatedAt: new Date().toISOString(),
      updatedBy: username,
    },
  ]);
  return await AxiosInstance.put(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.JOB_INTERVIEWPUT}/${JobInterviewID}?source=VideoInterviewAPI`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};


export const InterviewListData = async (
  accessToken,
  userInfo,
  isAdmin,
  pageIndex,
  pageSize,
  selector,
  isDesc,
  filterData
) => {
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  let userDetails = [];

  if (isAdmin === ("Global Administrator") || isAdmin === "VideoInterview Administrator") {
    userDetails = [
      {
        key: "exact",
        deleted: false,
      },
      {
        key: "exact",
        isArchive: false,
      },
    ];
  } else {
    userDetails = [
      {
        key: "contains",
        recruiterEmailId: userInfo,
      },
      {
        key: "exact",
        isArchive: false,
      },
      {
        key: "exact",
        deleted: false,
      },
    ];
  }



  let status = true;
  for (let val of filterData) {

    if (val.startDate) {
      if (val.startDate.toLowerCase().includes('undefined') || val.startDate.toLowerCase().includes('Invalid') || val.startDate.toLowerCase().includes('Date-undefined')) {
        status = false;
        break;
      }
    }

    else if (val.endDate) {
      if (val.endDate.toLowerCase().includes('undefined') || val.endDate.toLowerCase().includes('Invalid') || val.endDate.toLowerCase().includes('Date-undefined')) {
        status = false;
        break;
      }
    }
  }

  if (!status) {
    filterData = [];
  }

  const data = JSON.stringify({
    request: [
      ...userDetails,
      ...filterData,
      {
        include: [
          "candidateQuestionAnswerTime",
          "candidateName",
          "candidateEmail",
          "stage",
          "status",
          "video",
          "endDate",
          "startDate",
          "jobName",
          "candidateNumber",
          "jobId",
          "candidateId",
          "deleted",
          "isArchive",
          "recruiterEmailId",
          "questionnairesId",
          "candidateQuestions",
          "updatedAt",
          "candidateWarnings"
        ],
      },
      {
        sort: [selector, isDesc ? "desc" : "asc"],
      },
    ],
  });


  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.INTERVIEW_LIST}?perpage=${pageSize}&next=${pageIndex}&source=videoInterview`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const handleEmailTemplate = async (accessToken, template) => {
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  const data = JSON.stringify({
    tempName: "VideoInterviewTemplate.html",
    tempData: template,
  });
  return await AxiosInstance.post(
    `viPostUpdateTemplate?source=VideoInterview`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

// old ViewJobList
/*
export const ViewJobList = async (accessToken, userInfo, isAdmin, appmodule = '') => {
  let email = userInfo.email;
  let userName = userInfo.userName;
  // console.log('username',userInfo);
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  let recruiterInstance = [];

  if (isAdmin === "Global Administrator") {
    recruiterInstance = [];
  } else {
    recruiterInstance = [
      {
        key: "containsor",
        recruiterEmailId: email,
      },
      {
        key: "containsor",
        securitygroupuser: userName
      },
    ];
  }

  // For normal user (recruiter) get all jobs for searching if ats job present in local job master data    --start--
  if (isAdmin !== "Global Administrator" && appmodule === 'create_job_from_crm_module') {
    recruiterInstance = [];
  }
  // For normal user (recruiter) get all jobs for searching if ats job present in local job master data    --end--

  const data = JSON.stringify({
    request: [
      ...recruiterInstance,
      {
        key: "exact",
        deleted: false,
      },
      {
        sort: ["createdAt", "desc"],
      },
    ],
  });
  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.VIEW_JOB_LIST}?perpage=2100&next=1&sort=createdAt&source=videoInterview`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
*/
// old ViewJobList

// new ViewJobList
export const ViewJobList = async (accessToken, userInfo, isAdmin, pageIndex, pageSize, selector = '', isDesc = '', appmodule = '', filterConstraints = []) => {
  let email = userInfo.email;
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  let recruiterInstance = [];

  if (isAdmin === ("Global Administrator") || isAdmin === "VideoInterview Administrator") {
    recruiterInstance = [];
  } else {
    recruiterInstance = [
      {
        key: "containsor",
        recruiterEmailId: email,
      },
      {
        key: "containsall",
        securitygroupuser: userInfo.userName
      },
    ];
  }

  if ((isAdmin === ("Global Administrator") || isAdmin === "VideoInterview Administrator") && appmodule === 'create_job_from_crm_module') {
    recruiterInstance = [];
  }

  let _selector = "jobName";
  let _isDesc = "desc";

  if (selector != '') {
    _selector = selector;
    _isDesc = isDesc;
    if (isDesc) {
      _isDesc = "desc";
    }
    else {
      _isDesc = "asc";
    }
  }



  let status = true;
  for (let val of filterConstraints) {

    if (val.updatedAt) {
      if (val.updatedAt.toLowerCase().includes('undefined') || val.updatedAt.toLowerCase().includes('Invalid') || val.updatedAt.toLowerCase().includes('Date-undefined')) {
        status = false;
        break;
      }
    }

    else if (val.createdAt) {
      if (val.createdAt.toLowerCase().includes('undefined') || val.createdAt.toLowerCase().includes('Invalid') || val.createdAt.toLowerCase().includes('Date-undefined')) {
        status = false;
        break;
      }
    }
  }

  if (!status) {
    filterConstraints = [];
  };
  let appListModule = [];
  if (appmodule !== 'viewJobList') {
    appListModule = [
      {
        key: "status",
        jobStatus: "Close"
      }
    ]
  }
  const data = JSON.stringify({
    request: [
           ...recruiterInstance,
      ...filterConstraints,
      {
        key: "exact",
        deleted: false,
      },
      ...appListModule,
      {
        sort: [_selector, _isDesc],
      }

    ],
  });


  let sortQuery = '';
  if (selector == '') {
    sortQuery = '&sort=createdAt'
  }



  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.VIEW_JOB_LIST}?perpage=${pageSize}&next=${pageIndex}${sortQuery}&source=videoInterview`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
// new ViewJobList

export const DeleteCandidate = async (accessToken, userName, candidateId) => {
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  const data = JSON.stringify({
    updatedBy: userName,
    deleted: true,
  });
  return await AxiosInstance.put(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.DELETE_CANDIDATE}/ViCandidate/${candidateId}?source=VideoInterview`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const deleteInterviewListRow = async (
  accessToken,
  userName,
  JobInterviewId
) => {
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  const data = JSON.stringify({
    deleted: true,
    updatedAt: new Date().toISOString(),
    updatedBy: userName,
  });
  return await AxiosInstance.put(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.INTERVIEW_LIST_ROW_DELETE}/${JobInterviewId}?source=videoInterview`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const archiveInterviewListRow = async (
  accessToken,
  userName,
  JobInterviewId
) => {
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  const data = JSON.stringify({
    isArchive: true,
    updatedAt: new Date().toISOString(),
    updatedBy: userName,
  });
  return await AxiosInstance.put(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.ARCHIVE_CANDIDATE}/${JobInterviewId}?source=videoInterview`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const ArchiveList = async (
  accessToken,
  userInfo,
  isAdmin,
  skip,
  take,
  selector,
  isDesc,
  filterData
) => {
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  let email = userInfo.email;
  let recruiterInstance = [];
  if (isAdmin === "Global Administrator" || isAdmin === "VideoInterview Administrator") {
    recruiterInstance = [];
  } else {
    recruiterInstance = [
      {
        key: "contains",
        recruiterEmailId: email,
      },
    ];
  }


  let status = true;
  for (let val of filterData) {

    if (val.startDate) {
      if (val.startDate.toLowerCase().includes('undefined') || val.startDate.toLowerCase().includes('Invalid') || val.startDate.toLowerCase().includes('Date-undefined')) {
        status = false;
        break;
      }
    }

    else if (val.endDate) {
      if (val.endDate.toLowerCase().includes('undefined') || val.endDate.toLowerCase().includes('Invalid') || val.endDate.toLowerCase().includes('Date-undefined')) {
        status = false;
        break;
      }
    }
  }

  if (!status) {
    filterData = [];
  }


  const data = JSON.stringify({
    request: [
      ...recruiterInstance,
      ...filterData,
      {
        key: "exact",
        isArchive: true,
      },
      {
        sort: [selector, isDesc ? "desc" : "asc"],
      },
    ],
  });

  return await AxiosInstance.post(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.INTERVIEW_LIST}?perpage=${take}&next=${skip}&source=videoInterview`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const EditInterview = async (
  accessToken,
  JobInterviewId,
  startDate,
  endDate,
  userInfo
) => {
  let userName = userInfo.userName;
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  const data = JSON.stringify({
    startDate: startDate,
    endDate: endDate,
    updatedAt: new Date().toISOString(),
    updatedBy: userName,
  });
  return await AxiosInstance.put(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.EDIT_INTERVIEW_LIST}/${JobInterviewId}?source=videoInterview`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const updateJob = async (
  accessToken,
  jobData,
  userInfo,
  jobId,
  isAdmin
) => {
  let userName = userInfo.userName;
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  console.log(jobData.skills);
  let skills = "";
  if (typeof (jobData.skills) === 'string') {
    skills = [jobData.skills]
  }
  else {
    skills = jobData.skills
  }
  const data = JSON.stringify({
    recruiterEmailId: isAdmin ? jobData.email : userInfo.email,
    recruiterMobileNumber: jobData.recruiterMobileNumber,
    jobName: jobData.jobName,
    jobStatus: jobData.jobStatus,
    designation: jobData.designation,
    skills: skills,
    location: jobData.location,
    experience: [
      {
        experienceFrom: jobData.experience_from,
        experienceTo: jobData.experience_to,
      },
    ],
    ctc: [
      {
        ctcfrom: jobData.ctc_from,
        ctcto: jobData.ctc_to,
      },
    ],
    updatedAt: new Date().toISOString(),
    updatedBy: userName,
  });
  return await AxiosInstance.put(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.UPDATE_JOB}/${jobId}?source=videoInterview`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};

export const deleteJob = async (jobId, accessToken, userInfo) => {
  const headers = {
    "Content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  const data = JSON.stringify({
    deleted: true,
    updatedAt: new Date().toISOString(),
    updatedBy: userInfo.userName,
  });
  return await AxiosInstance.put(
    `${AppConstants.serverConstants.API_ENDPOINTS.QUESTION.DELETE_JOB}/${jobId}?source=videoInterview`,
    data,
    { headers: headers }
  )
    .then((response) => response)
    .catch((error) => error);
};
