import { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import globalhunt from '../../../../Assets/icons/GlobalHunt_India_logo.svg';
import { useSelector, useDispatch } from 'react-redux';
import { isMailSend, ScheduleInterviewInfotoRecruiter, SendMail } from '../../../../handlers/mail_handler';
import { useUserAuthContext } from '../../../../utils/Contexts/Auth/UserAuthProvider';
import { ScheduleInterview, JobInterview, JobInterviewID, handleJobs } from '../../../../handlers/job_handlers';
import { getEmailTemplate } from '../../../../handlers/mail_handler';
import parse from 'html-react-parser';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { environment } from '../../../../utils/Environment/Environment';
import { EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER, EMAIL_TEMPLATE_KEYWORDS } from '../../../../Constants/email-template-keywords';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { CI_InitialStateAction } from '../../../../utils/Redux/Actions';

import { JobInterviewIDApiMailErrorHandler } from "../../../../handlers/job_handlers";


function EmailTemplate({ proceedToSendMail, drawerOpen, setInterviewScheduleInfo }) {
    const { interviewTimeLineInfoState, selectedCandidates, selectedQuestionSetId, selectedQuestions, selectedJob, interviewType, time_duration, selectedCesCandidateLocalId } = useSelector(state => state.handleCreateInterviewInitialState);
    const interviewState = useSelector(state => state.handleCreateInterviewInitialState);
    const isLocalJob = interviewState.selectedJob.isLocal;
    const jobId = interviewState.selectedJob.jobId;
    const { interviewDateTo, interviewDateFrom, interviewTimeTo, interviewTimeFrom, allDay, timeZone, candidatesEmail, invitationTemplate } = interviewTimeLineInfoState;
    const { accessToken, userInfo, userRole } = useUserAuthContext();
    const [scheduleInterviewId, setScheduleInterviewId] = useState('');
    const [jobInterviewId, setJobInterviewId] = useState('');
    const [emailSendDisabled, setEmailSendDisabled] = useState(true);
    const [template, SetTemplate] = useState('');
    const [scheduleInterviewLoader, setScheduleInterviewLoader] = useState(false);
    const [emailSendLoader, setEmailSendLoader] = useState(false);
    const [emailSentAlert, setEmailSentALert] = useState({ display: false, msg: '' });
    const [interviewScheduleError, setInterviewScheduleError] = useState({ display: false, msg: '' });
    const [interviewScheduled, setInterviewScheduled] = useState(false);
    const [mailSend, setMailSend] = useState(false);
    const [convertedCandidateList, setConvertedCandidateList] = useState([]);
    const htmlTemplate = useRef();
    const username = useRef('');
    const mids = useRef([]);
    const navigate = useNavigate();
    const interviewDateInfoStr = useRef({ startDate: '', endDate: '' });
    const dispatch = useDispatch();

    const imgstyle = {
        width: 150,
        height: 100
    };

    const boxSize = {
        width: 1000,
    }

    let startDateInfo = new Date(interviewDateFrom).setHours(interviewTimeFrom.substring(0, 2), interviewTimeFrom.substring(3, 5));
    let endDateInfo = new Date(interviewDateTo).setHours(interviewTimeTo.substring(0, 2), interviewTimeTo.substring(3, 5));

    convertTimeZone(new Date(startDateInfo), timeZone)


    function convertTimeZone(currentDateTime, timeZone) {
        const date = new Date(currentDateTime).toLocaleString('en-US', {
            timeZone: timeZone.value,
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit"
        })
        interviewDateInfoStr.current = date + ' (' + timeZone.value + ')';
    }

    if (mailSend) {
        setTimeout(() => {
            navigate('/interview/interview-list', { state: 'interview_scheduled_and_redirected' });
        }, 5000);
    };



    console.log(userInfo, userRole, time_duration, 'sdfbsdjiifb');
    useEffect(() => {
        const recruiterEmailId = userInfo.email;
        let isAdmin = false;
        if (userRole === "Global Administrator" || userRole === "VideoInterview Administrator") {
            isAdmin = true;
        }
        if (!scheduleInterviewId) return;
        JobInterview(accessToken, selectedJob, scheduleInterviewId, selectedCandidates, username.current, interviewTimeLineInfoState, time_duration, isLocalJob, timeZone.value, recruiterEmailId, isAdmin)
            .then(res => {
                if (res.status) {
                    if (res.data.status === "succeed") {
                        setJobInterviewId(res.data.data.id);
                        mids.current = res.data.data.id;
                        dispatch(CI_InitialStateAction('store_candidates_mid', { candidates: [...selectedCandidates], mids: res.data.data.id }));
                    }
                }
            })
            .catch(res => console.log(res))
    }, [scheduleInterviewId]);

    useEffect(() => {
        username.current = userInfo.email.substring(0, userInfo.email.indexOf('@'));
        let navBtnInner = document.getElementById('nav-btn-inner');
        navBtnInner.classList.add('nav-btn-inner-email-template-override');
        handleScheduleInterview();
        return () => {
            navBtnInner.classList.remove('nav-btn-inner-email-template-override');
        }
    }, []);
    let listdata = [];
    // async function myFunction() {
    //     try {
    //         const res = await handleJobs('getAttachedCandidates', accessToken, '', jobId);
    //         listdata = res.data.data.response;
    //         // setConvertedCandidateList(listdata);
    //         console.log('testingjob', listdata);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    // useEffect(() => {
    //     handleJobs('getAttachedCandidates', accessToken, '', jobId)
    //         .then(res => {
    //             // listdata = (res.data.data.response)
    //             setConvertedCandidateList(listdata);
    //             console.log(res.data.data, 'testingjob');
    //         })
    //         .catch(err => console.log(err));
    // }, [])
    // handleJobs('getAttachedCandidates', accessToken, '', jobId)
    //     .then(res => {
    //         listdata = (res.data.data.response)
    //         console.log(res.data.data, 'testingjob', convertedCandidateList);
    //     })
    //     .catch(err => console.log(err))
    // interviewState.selectedCesCandidateLocalId.selectedLocalId;
    // console.log('testingjob', listdata);

    useEffect(() => {
        async function myFunction() {
            try {
                const res = await handleJobs('getAttachedCandidates', accessToken, '', jobId);
                if (res.status) {
                    if (res.data.status === "succeed") {
                        if (res.data.data != null) {
                            listdata = res.data.data.response;
                            setConvertedCandidateList(listdata);
                        }
                    }
                }

                // console.log('testingjob', listdata);
            } catch (error) {
                console.error(error);
            }
        };
        myFunction();
    }, [mailSend, jobInterviewId, selectedCandidates, accessToken]);


    useEffect(() => {
        if (mailSend && jobInterviewId.length && selectedCandidates.length) {
            // myFunction(); 
            if (selectedCandidates.length && convertedCandidateList.length) {
                selectedCandidates.forEach(item => {
                    convertedCandidateList.forEach(CandidateList => {
                        if (item.objectId == CandidateList.attachCandidate[0].atsCandidateId) {
                            // console.log('report', resItem.attachCandidate[0].candidateEmail == cnd.candidate.email, resItem.attachCandidate[0].candidateId, cnd.objectId);
                            item.objectId = CandidateList.attachCandidate[0].candidateId;
                        }
                    })
                });
            }
            for (let i = 0; i < jobInterviewId.length; i++) {
                if (!isLocalJob) {
                    if (selectedCandidates[i].attachCandidate != undefined) {
                        isMailSend(accessToken, userInfo.userName, jobInterviewId[i], selectedCandidates[i].attachCandidate[0].candidateId)
                            .then(res => console.log(res))
                            .catch(err => console.log(err))
                    }
                    else {
                        isMailSend(accessToken, userInfo.userName, jobInterviewId[i], selectedCandidates[i].objectId)
                            .then(res => console.log(res))
                            .catch(err => console.log(err))
                    }
                }
                else {
                    isMailSend(accessToken, userInfo.userName, jobInterviewId[i], selectedCandidates[i].attachCandidate[0].candidateId)
                        .then(res => console.log(res))
                        .catch(err => console.log(err))
                }
            }
        }
    }, [mailSend, jobInterviewId, selectedCandidates, accessToken]);
    useEffect(() => {
        if (!jobInterviewId.length) return;
        if (selectedQuestions) {
            for (let i = 0; i < jobInterviewId.length; i++) {
                JobInterviewID(accessToken, jobInterviewId[i], selectedQuestionSetId,
                    username.current, selectedQuestions)
                    .then(res => {
                        if (!res.status) {
                            setInterviewScheduleError({ display: true, msg: 'Something went wrong!' });
                            setTimeout(() => {
                                setScheduleInterviewLoader(false);
                            }, [1000])
                            return;
                        }
                        setInterviewScheduleError({ display: false, msg: '' });
                        setEmailSendDisabled(false);
                        setScheduleInterviewLoader(false);
                        setInterviewScheduled(true);
                        getEmailTemplate(accessToken)
                            .then(res => {
                                if (res.status) {
                                    if (res.data.status === "succeed") {
                                        SetTemplate(res.data.data.message)
                                    }
                                }

                            })
                            .catch(res => console.log(res))
                    })
                    .catch(res => console.log(res))
            }
        }

    }, [jobInterviewId.length]);

    // console.log(selectedQuestions);
    // let startDateInfo = new Date(interviewDateFrom).setHours(interviewTimeFrom.substring(0, 2), interviewTimeFrom.substring(3, 5));
    // let endDateInfo = new Date(interviewDateTo).setHours(interviewTimeTo.substring(0, 2), interviewTimeTo.substring(3, 5));

    function sendEmail() {

        setEmailSendLoader(true);
        let mailTemplatePreviewMain = document.querySelectorAll('.mail-template-preview-main');
        for (let i = 0; i < mids.current.length; i++) {
            let html = `<!DOCTYPE html>
            <html lang="en" xmlns="http://www.w3.org/1999/xhtml">
            
            <head>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Interview mail</title>
            </head>
            
            <body style="width:100%;margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%">
                <div>
                    ${mailTemplatePreviewMain[i].innerHTML}
                </div>
            </body>
            
            </html>`
            // SendMail(accessToken, selectedCandidates[i].attachCandidate[0].candidateEmail, html)
            //     .then(res => {
            //        // console.log(res)
            //         setEmailSentALert({ display: true, msg: 'Email sent successfully.' });
            //         setEmailSendLoader(false);
            //     })
            //     .catch(res => {
            //         console.log(res)
            //         setEmailSentALert({ display: true, msg: 'Something went wrong.' });
            //         setEmailSendLoader(false);
            //     })
            // let startDateInfo = new Date(interviewDateFrom).setHours(interviewTimeFrom.substring(0, 2), interviewTimeFrom.substring(3, 5));
            // let endDateInfo = new Date(interviewDateTo).setHours(interviewTimeTo.substring(0, 2), interviewTimeTo.substring(3, 5));


            ScheduleInterviewInfotoRecruiter(accessToken, isLocalJob ? selectedCandidates[i].attachCandidate[0].candidateEmail : selectedCandidates[i].candidate.email, html, startDateInfo, endDateInfo, interviewType, timeZone)
                .then(res => {
                    console.log('email-check res', res);
                    let currentStatus = 0;
                    if (res) {
                        if (res.data.success === "succeed!") {
                            currentStatus = 1;
                            setEmailSentALert({ display: true, msg: 'Email sent successfully.' });
                            setEmailSendLoader(false);
                            setMailSend(true);
                            setInterviewScheduleInfo({ state: 'interview_scheduled_email_send', sentStatus: 'email_sent_to_candidates', text: 'Email sent successfully' });
                            dispatch(CI_InitialStateAction('remove_all_selected_redux_data_after_mail_send', null));
                        }
                    }

                    if (currentStatus === 0) {
                        setEmailSentALert({ display: true, msg: 'Failed to send email.' });
                        setInterviewScheduleInfo({ state: 'interview_scheduled_email_send', sentStatus: 'email_not_sent_to', text: 'Something went wrong.' });
                        setEmailSendLoader(false);
                        JobInterviewIDApiMailErrorHandler(accessToken, jobInterviewId[i], username.current);
                    }

                })
                .catch(res => {
                    console.log('email-check err', res);
                    setEmailSentALert({ display: true, msg: 'Failed to send email.' });
                    setInterviewScheduleInfo({ state: 'interview_scheduled_email_send', sentStatus: 'email_not_sent_to', text: 'Something went wrong.' });
                    setEmailSendLoader(false);
                    JobInterviewIDApiMailErrorHandler(accessToken, jobInterviewId[i], username.current);
                })
        }
    };


    function bindTextToElements(element, displayText, innerText = '', href = '', optionalText) {

        console.log(optionalText);
        if (element != '') {
            if (href === 'href') {
                if (element.nodeName === 'A') {
                    element.href = displayText + optionalText;
                }
                else if (element.parentNode.nodeName === 'A') {
                    element.parentNode.href = displayText + optionalText;
                }

            }
            if (innerText === 'innerText') {
                if (element.nodeName === 'A') {
                    element.href = displayText + optionalText;
                    element.innerText = displayText + optionalText;
                }
                else if (element.parentNode.nodeName === 'A') {
                    element.parentNode.href = displayText + optionalText;
                    element.parentNode.innerText = displayText + optionalText;
                }
                else {
                    console.log('displayText + optionalText', element.parentNode.children);
                    for (let i = 0; i < element.parentNode.children.length; i++) {
                        //element.parentNode.children[i].innerText = ''
                    }
                    element.innerText = displayText + optionalText;
                    console.log('find element', element);
                }
            }
        }
    }


    function capitalize_first_letter(string) {
        const strArr = string.split(' ');
        let start_date_str = '';
        for (let i = 0; i < strArr.length; i++) {
            start_date_str += strArr[i].substring(0, 1).toUpperCase() + strArr[i].substring(1) + ' ';
        }

        return start_date_str;
    }


    const formattedDate = new Date(endDateInfo).toLocaleString('en-US', {
        timeZone: timeZone.value,
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit"
    }) + ' (' + timeZone.value + ')';

    useEffect(() => {
        if (template) {
            let url = environment.videoInterviewUrl
            // let startDateInfo = new Date(interviewDateFrom).setHours(interviewTimeFrom.substring(0, 2), interviewTimeFrom.substring(3, 5));
            mids.current.forEach((item, index) => {
                if (index < mids.current.length - 1) {
                    let row_border = '';
                    if (index < mids.current.length - 2) {
                        row_border = '<hr/>'
                    }
                    htmlTemplate.current.innerHTML += ` <div class='mail-template-preview-main'>
                                                            ${window.atob(template)}
                                                            ${row_border}
                                                         </div>`
                }
            })
            let mailTemplatePreviewMain = document.querySelectorAll('.mail-template-preview-main');
            console.log('mailTemplatePreviewMain', mailTemplatePreviewMain[0], htmlTemplate.current.querySelectorAll('*'));

            const optionalTexts = {
                GREETING_KEYWORD: '',
                POSITION: '',
                START_DATE: '',
                INTERVIEW_INVITATION: '',
                // INTERVIEW_APP_URL: ''
            };


            let text = '';
            let textLen = '';
            let textIndex = '';


            for (let i = 0; i < mailTemplatePreviewMain.length; i++) {

                let elements = mailTemplatePreviewMain[i].querySelectorAll('*');

                let CongratulationsHolder = '';
                let PositionHolder = '';
                let StartDateHolder = '';
                let InvitationHolder = '';
                let LinkHolder = '';




                for (let j = 0; j < elements.length; j++) {
                    if (elements[j].innerText.toLowerCase().includes(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.GREETING_KEYWORD)) {
                        CongratulationsHolder = elements[j];

                        text = EMAIL_TEMPLATE_KEYWORDS.GREETING_KEYWORD;
                        textLen = text.length;
                        textIndex = CongratulationsHolder.innerText.toLowerCase().indexOf(text);
                        optionalTexts.GREETING_KEYWORD = CongratulationsHolder.innerText.slice(textLen);
                    }
                    if (elements[j].innerText.toLowerCase().includes(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.POSITION)) {
                        PositionHolder = elements[j];

                        text = EMAIL_TEMPLATE_KEYWORDS.POSITION;
                        textLen = text.length;
                        textIndex = PositionHolder.innerText.toLowerCase().indexOf(text);
                        optionalTexts.POSITION = PositionHolder.innerText.slice(textLen);
                        console.log('optionalTexts', optionalTexts);
                    }
                    if (elements[j].innerText.toLowerCase().includes(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.START_DATE)) {
                        StartDateHolder = elements[j];

                        text = EMAIL_TEMPLATE_KEYWORDS.START_DATE;
                        textLen = text.length;
                        textIndex = StartDateHolder.innerText.toLowerCase().indexOf(text);
                        optionalTexts.START_DATE = StartDateHolder.innerText.slice(textLen);

                    }

                    if (elements[j].innerText.toLowerCase().includes(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.INTERVIEW_INVITATION)) {
                        InvitationHolder = elements[j];

                        text = EMAIL_TEMPLATE_KEYWORDS.INTERVIEW_INVITATION;
                        textLen = text.length;
                        textIndex = InvitationHolder.innerText.toLowerCase().indexOf(text);
                        optionalTexts.INTERVIEW_INVITATION = InvitationHolder.innerText.slice(textLen);
                    }
                    // if (elements[j].innerText.includes(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.INTERVIEW_APP_URL.slice(0, EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.INTERVIEW_APP_URL.length - 1))) {
                    //     LinkHolder = elements[j];

                    //     text = EMAIL_TEMPLATE_KEYWORDS.INTERVIEW_APP_URL;
                    //     textLen = text.length;
                    //     textIndex = LinkHolder.innerText.toLowerCase().indexOf(text);
                    //     optionalTexts.INTERVIEW_APP_URL = LinkHolder.innerText.slice(textLen);
                    // }
                }

                bindTextToElements(
                    CongratulationsHolder,
                    `${capitalize_first_letter(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.GREETING_KEYWORD)} ${isLocalJob ? selectedCandidates[i].attachCandidate[0].candidateName : selectedCandidates[i].candidate.name}`,
                    'innerText',
                    '',
                    optionalTexts.GREETING_KEYWORD
                );

                bindTextToElements(
                    PositionHolder,
                    `${capitalize_first_letter(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.POSITION)} ${selectedJob.jobName}`,
                    'innerText',
                    '',
                    optionalTexts.POSITION
                );

                bindTextToElements(
                    StartDateHolder,
                    `${capitalize_first_letter(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.START_DATE)} ${interviewDateInfoStr.current} (Duration: ${time_duration}) (End Date: ${formattedDate})`,
                    'innerText',
                    '',
                    optionalTexts.START_DATE
                );

                // bindTextToElements(
                //     LinkHolder,
                //     `${environment.videoInterviewUrl}?mid=${mids.current[i]}`,
                //     'innerText',
                //     'href',
                //     optionalTexts.INTERVIEW_APP_URL
                // );

                bindTextToElements(
                    InvitationHolder,
                    `${environment.videoInterviewUrl}?mid=${mids.current[i]}`,
                    '',
                    'href',
                    optionalTexts.INTERVIEW_INVITATION
                );

            }
            sendEmail();
        }
    }, [template]);

    console.log(template, 'template');

    const handleScheduleInterview = () => {
        setInterviewScheduleInfo(prevState => ({ ...prevState, sentStatus: 'loading' }));
        setScheduleInterviewLoader(true);
        setEmailSentALert({ display: false, msg: '' });
        ScheduleInterview(accessToken, selectedJob, interviewType, interviewDateFrom, interviewTimeFrom)
            .then(res => {
                // console.log(res, res.data.data["id's"][0]);
                if (res.status) {
                    if (res.data.status === "succeed") {
                        setScheduleInterviewId(res.data.data.id[0])
                    }
                }

            })
            .catch(res => console.log(res))
    }

    let str = window.atob(template)

    //let html = parse(str);
    console.log('interviewState', interviewState);


    let interviewSendAlert = '';

    if (interviewScheduleError.display || emailSentAlert.msg === 'Failed to send email.') {
        interviewSendAlert = 'error';
    }

    return (
        <>
            {
                scheduleInterviewLoader && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '45%', left: '47%' }}>
                    <CircularProgress />
                </Box>
            }

            {
                (emailSentAlert.display || interviewScheduleError.display) &&
                <Collapse in={(emailSentAlert.display || interviewScheduleError.display)}>

                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: '10%' }}>
                        <Alert
                            sx={{ boxShadow: '1px 2px 2px #ece9e9' }}
                            // setInterviewScheduleInfo
                            // severity={(interviewScheduleError.display) ? 'error' : 'success'}
                            severity={interviewSendAlert == 'error' ? 'error' : 'success'}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        if (interviewScheduleError.display) {
                                            setInterviewScheduleError({ display: false, msg: '' })
                                        }
                                        else {
                                            setEmailSentALert({ display: false, msg: '' })
                                        }

                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        >{emailSentAlert.msg || interviewScheduleError.msg}</Alert>
                    </Box>
                </Collapse>

            }
            {

                <div ref={htmlTemplate} className='mail-template-preview'>
                    <div className='mail-template-preview-main'>
                        {
                            parse(str)
                        }
                        {str && <div className='row-border'><hr /></div>}
                    </div>
                </div>
            }

            {/* <Box sx={{ width: interviewScheduled ? '550px' : '400px', display: 'flex', justifyContent: 'space-between', pr: 5, position: 'absolute', bottom: '100px', bottom: '100px', left: drawerOpen ? '265px' : '100px' }} className='interview-schedule-btn'>
                <Button variant="contained" disabled={jobInterviewId.length ? true : false} onClick={handleScheduleInterview} className='interview-schedule-btn1'>{interviewScheduled ? 'Interview Scheduled Successfully' : 'Schedule Interview'}</Button>
                <Button variant="contained" disabled={emailSendDisabled} sx={{ background: '#1890ff' }} onClick={() => sendEmail()}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            Send Email
                        </div>
                        {
                            emailSendLoader && <CircularProgress size={18} sx={{ marginLeft: '10px', color: '#01579b' }} />
                        }

                    </div>
                </Button>
            </Box> */}
        </>
    )
}
export default EmailTemplate;